import { FC } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import styles from "./UomContests.module.css";

enum ActiveLink {
  UomContestsAll = 0,
  UomContestsImages = 1,
  AddUomContests = 2,
  EditUomContests = 3,
}

const UomContests: FC = () => {
  let selected: ActiveLink | null;
  const location = useLocation();
  switch (location.pathname) {
    case "/uom/contests/add":
      selected = ActiveLink.AddUomContests;
      break;
    case "/uom/contests/gallery":
      selected = ActiveLink.UomContestsImages;
      break;
    case "/uom/contests":
      selected = ActiveLink.UomContestsAll;
      break;
    default:
      selected = ActiveLink.EditUomContests;
      break;
  }

  return (
    <>
      <nav className={styles["contests-navigation"]}>
        <Link
          to=""
          className={
            selected === ActiveLink.UomContestsAll
              ? styles["contests-navigation-link_active"]
              : styles["contests-navigation-link_inactive"]
          }
        >
          Προβολή
        </Link>
        <Link
          to="gallery"
          className={
            selected === ActiveLink.UomContestsImages
              ? styles["contests-navigation-link_active"]
              : styles["contests-navigation-link_inactive"]
          }
        >
          Εικόνες
        </Link>
        <Link
          to="add"
          className={
            selected === ActiveLink.AddUomContests
              ? styles["contests-navigation-link_active"]
              : styles["contests-navigation-link_inactive"]
          }
        >
          Δημιουργία
        </Link>
        <Link
          to="edit"
          aria-disabled
          onClick={(e) => e.preventDefault()}
          className={`${
            selected === ActiveLink.EditUomContests
              ? styles["contests-navigation-link_active"]
              : styles["contests-navigation-link_inactive"]
          } ${styles["contests-navigation-link_disabled"]}`}
        >
          Επεξεργασία
        </Link>
      </nav>
      <Outlet />
    </>
  );
};

export default UomContests;
