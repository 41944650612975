import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { IVideoOrder } from "../../../interfaces/IVideo";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { VideoCard } from "..";

import styles from "./VideoGrid.module.css";

interface VideoGridProps {
  videos: IVideoOrder[];
  onReorder: (newOrder: IVideoOrder[]) => void;
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const VideoGrid: FC<VideoGridProps> = ({
  videos: initialVideos,
  onReorder,
  onRefresh,
}) => {
  const navigate: NavigateFunction = useNavigate();

  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  const [videos, setVideos] = useState<IVideoOrder[]>([]);

  const isChanged = useMemo(() => {
    for (let i = 0; i < videos.length; i++) {
      if (videos[i].order !== initialVideos[i].order) return true;
    }
    return false;
  }, [videos, initialVideos]);

  /**
   * Handle the drag & drop of a video
   * @param dragIndex The initial (drap) index of the video
   * @param hoverIndex The new (drop) index of the video
   */
  const moveVideo = (dragIndex: number, hoverIndex: number) => {
    setVideos((prevVideos) => {
      const newVideos = [...prevVideos];
      const dragVideo = prevVideos[dragIndex];

      newVideos.splice(dragIndex, 1);
      newVideos.splice(hoverIndex, 0, dragVideo);
      return newVideos;
    });
  };

  // whenever initial list of videos is changed (e.g. refresh or response from API), update the list of videos
  useEffect(() => {
    setVideos(initialVideos);
  }, [initialVideos]);

  // handle request to delete a video
  const deleteHandler = (id: string) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/videos/delete/${id}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  // navigate to edit video page
  const editHandler = (id: string) => {
    navigate(`/videos/edit/${id}`);
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {videos && videos.length > 0 && (
        <div className={classNames(styles["videos-grid"])}>
          <DndProvider backend={HTML5Backend}>
            {videos.map((video, index) => (
              <VideoCard
                key={`t-${video.video.id}`}
                index={index}
                order={video.order}
                video={video.video}
                moveVideo={moveVideo}
                onDelete={deleteHandler}
                onEdit={editHandler}
                isLoading={isLoading}
              />
            ))}
          </DndProvider>
        </div>
      )}
      <div className={styles["reorder-btn-container"]}>
        <button
          className={styles["reorder-btn"]}
          onClick={() => onReorder(videos)}
          disabled={isLoading || !isChanged}
        >
          Αποθήκευση Φωτογραφιών
        </button>
      </div>
    </div>
  );
};

export default VideoGrid;
