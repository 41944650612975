import { FC, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import { ModalDialog, ModalDialogActionStyle } from "../..";

import styles from "./GalleryCard.module.css";

interface GalleryCardProps {
  image: string;
  onDelete: (name: string) => void;
  isLoading: boolean;
}

const GalleryCard: FC<GalleryCardProps> = ({ image, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(image.split(".")[0]);
    onCancelAction();
  };

  return (
    <div className={styles["gallery-container"]}>
      <div className={styles["gallery-image"]}>
        <img
          src={`${process.env.REACT_APP_BASE_URL}/images/uom-contests/${image}`}
          alt="Εικόνα Διαγωνισμού"
        />
      </div>
      <div className={styles["gallery-actions"]}>
        <AiFillDelete onClick={onShowDeleteDialog} />
      </div>
      {showDeleteDialog && (
        <ModalDialog
          title="Διαγραφή Εικόνας"
          content={`Είστε σίγουροι ότι θέλετε να διαγράψετε την εικόνα;`}
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: onConfirmAction,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </div>
  );
};

export default GalleryCard;
