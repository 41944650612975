import { FC, useContext, useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { GalleryCard } from "..";

import styles from "./GalleryGrid.module.css";

interface IGalleryImage {
  order: number;
  name: string;
}

interface GalleryGridProps {
  images: IGalleryImage[];
  onReorder: (newOrder: IGalleryImage[]) => void;
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const GalleryGrid: FC<GalleryGridProps> = ({
  images: initialImages,
  onReorder,
  onRefresh,
}) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  const [images, setImages] = useState<IGalleryImage[]>([]);

  const isChanged = useMemo(() => {
    for (let i = 0; i < images.length; i++) {
      if (images[i].order !== initialImages[i].order) return true;
    }
    return false;
  }, [images, initialImages]);

  /**
   * Handle the drag & drop of an image
   * @param dragIndex The initial (drap) index of the image
   * @param hoverIndex The new (drop) index of the image
   */
  const moveImage = (dragIndex: number, hoverIndex: number) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      const dragImage = prevImages[dragIndex];

      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragImage);
      return newImages;
    });
  };

  // whenever initial list of images is changed (e.g. refresh or response from API), update the list of images
  useEffect(() => {
    setImages(initialImages);
  }, [initialImages]);

  // handle request to delete a disc
  const deleteHandler = (order: number) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      window.location.reload();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/gallery/delete/${order}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {images && images.length > 0 && (
        <div className={classNames(styles["gallery-grid"])}>
          <DndProvider backend={HTML5Backend}>
            {images.map((img, index) => (
              <GalleryCard
                key={`i-${img.name}-${img.order}`}
                index={index}
                order={img.order}
                image={img.name}
                moveImage={moveImage}
                onDelete={deleteHandler}
                isLoading={isLoading}
              />
            ))}
          </DndProvider>
        </div>
      )}
      <div className={styles["reorder-btn-container"]}>
        <button
          className={styles["reorder-btn"]}
          onClick={() => onReorder(images)}
          disabled={isLoading || !isChanged}
        >
          Αποθήκευση Gallery
        </button>
      </div>
    </div>
  );
};

export default GalleryGrid;
