/**
 * Check if given file is an pdf (jpg, jpeg, png)
 * @param {File| undefined} pdfFile The file to be tested
 * @returns True or false, depending on whether given file is an pdf
 */
export const validatePdfFile = (pdfFile: File | undefined): boolean => {
  if (!pdfFile) return false;
  const pdfNamePattern = /^.+\.pdf$/;
  const pdfTypePattern = /^application\/pdf$/;
  return (
    pdfNamePattern.test(pdfFile.name.toLowerCase()) &&
    pdfTypePattern.test(pdfFile.type)
  );
};
