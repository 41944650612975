import { FC } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import styles from "./Concerts.module.css";

enum ActiveLink {
  ConcertsComing = 0,
  ConcertsArchive = 1,
  CreateConcert = 2,
  EditConcert = 3,
}

const Concerts: FC = () => {
  let selected: ActiveLink | null;
  const location = useLocation();
  switch (location.pathname) {
    case "/concerts/create":
      selected = ActiveLink.CreateConcert;
      break;
    case "/concerts/archive":
      selected = ActiveLink.ConcertsArchive;
      break;
    case "/concerts":
      selected = ActiveLink.ConcertsComing;
      break;
    default:
      selected = ActiveLink.EditConcert;
      break;
  }

  return (
    <>
      <nav className={styles["concerts-navigation"]}>
        <Link
          to=""
          className={
            selected === ActiveLink.ConcertsComing
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          }
        >
          Ερχόμενες
        </Link>
        <Link
          to="archive"
          className={
            selected === ActiveLink.ConcertsArchive
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          }
        >
          Αρχείο
        </Link>
        <Link
          to="create"
          className={
            selected === ActiveLink.CreateConcert
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          }
        >
          Δημιουργία
        </Link>
        <Link
          to="edit"
          aria-disabled
          onClick={(e)=> e.preventDefault()}
          className={
            `${selected === ActiveLink.EditConcert
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]} ${styles["concerts-navigation-link_disabled"]}`
          }
        >
          Επεξεργασία
        </Link>
      </nav>
      <Outlet />
    </>
  );
};

export default Concerts;
