import { FC, useState, useContext } from "react";

import { ModalDialog, ModalDialogActionStyle } from "../..";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import styles from "./DiscographyImageDisplay.module.css";

interface DiscographyImageDisplayProps {
  year: string;
  image: string | null | undefined;
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const DiscographyImageDisplay: FC<DiscographyImageDisplayProps> = ({
  year,
  image,
  onRefresh,
}) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  // useHttp custom hook
  const { error, sendRequest } = useHttp();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  if (!image) return <></>;

  const deleteDiscographyImage = (): void => {
    if (!image || !showDelete) {
      onCancelAction();
      return;
    }

    onCancelAction();
    if (!year) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    // send DELETE request to API's route /admin/discography/delete-image/:year
    const url = `${process.env.REACT_APP_API_URL}/admin/discography/delete-image/${year}`;
    sendRequest(
      {
        url,
        method: "DELETE",
        token: auth.token,
      },
      transformResponse
    );
  };

  const onCancelAction = () => {
    setShowDelete(false);
  };

  return (
    <>
      <h2 className={styles["images-title"]}>Εικόνα Δίσκου</h2>
      {error && error.trim() !== "" && (
        <p className={styles["images-error"]}>{error}</p>
      )}
      <div className={styles["container"]}>
        <div
          className={styles["image-container"]}
          onClick={() => setShowDelete(true)}
        >
          <img
            src={`${process.env.REACT_APP_BASE_URL}/images/discography/${image}`}
            alt="Επιλεγμένη εικόνα"
            aria-label="Πατήστε για επιλογή εικόνας"
          />
        </div>
      </div>
      {showDelete && (
        <ModalDialog
          title="Διαγραφή Εικόνας"
          content="Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την εικόνα; Προσοχή, η ενέργεια δε μπορεί να αναιρεθεί."
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: deleteDiscographyImage,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </>
  );
};

export default DiscographyImageDisplay;
