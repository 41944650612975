/**
 * Check if time field has a valid form
 * @param {string} time The time to be tested
 * @returns {string | null}, depending on verification success (if not null, value contains the failure message)
 */
export const validateTime = (time: string | null): string | null => {
  if (!time || time.trim().length !== 5)
    return "Το πεδίο δε μπορεί να είναι κενό";

  const timeFields = time.split(":");
  if (timeFields.length !== 2) return "Μη έγκυρη μορφή ώρας";

  // Check 24h format hour
  if (
    isNaN(parseInt(timeFields[0])) ||
    parseInt(timeFields[0]) < 0 ||
    parseInt(timeFields[0]) > 23
  )
    return "Μη έγκυρη μορφή ώρας";

  // Check minute
  if (
    isNaN(parseInt(timeFields[1])) ||
    parseInt(timeFields[1]) < 0 ||
    parseInt(timeFields[1]) > 59
  )
    return "Μη έγκυρη μορφή ώρας";

  return null;
};
