import {
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  FormEvent,
} from "react";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import {
  validateText,
  validateDigitText,
  validateUrl,
} from "../../../utils/input";

import {
  DiscographyInfoForm,
  DiscographyImageForm,
  DiscographyImageDisplay,
} from "../index";

import { Discography } from "../../../interfaces/Discography";

import styles from "./EditDiscographyForm.module.css";

interface ApiResponse {
  message: string;
}

const EditDiscographyForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;
  const params = useParams();
  const { discographyInitialYear } = params;

  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  if (!discographyInitialYear || discographyInitialYear.trim() === "")
    navigate("/discography", { replace: true });

  const [discographyYear, setDiscographyYear] = useState<string>(
    `${new Date().getFullYear()}`
  );
  const [discographyLink, setDiscographyLink] = useState<string | null>("");
  const [discographyPublisherEl, setDiscographyPublisherEl] =
    useState<string>("");
  const [discographyPublisherEn, setDiscographyPublisherEn] =
    useState<string>("");
  const [discographyPublisherDe, setDiscographyPublisherDe] =
    useState<string>("");
  const [discographyTitleEl, setDiscographyTitleEl] = useState<string>("");
  const [discographyTitleEn, setDiscographyTitleEn] = useState<string>("");
  const [discographyTitleDe, setDiscographyTitleDe] = useState<string>("");
  const [discographySubtitleEl, setDiscographySubtitleEl] =
    useState<string>("");
  const [discographySubtitleEn, setDiscographySubtitleEn] =
    useState<string>("");
  const [discographySubtitleDe, setDiscographySubtitleDe] =
    useState<string>("");
  const [discographyDescriptionEl, setDiscographyDescriptionEl] = useState<
    string[] | null
  >([]);
  const [discographyDescriptionEn, setDiscographyDescriptionEn] = useState<
    string[] | null
  >([]);
  const [discographyDescriptionDe, setDiscographyDescriptionDe] = useState<
    string[] | null
  >([]);
  const [discographyMedia, setDiscographyMedia] = useState<string[] | null>([]);

  const [discographyImage, setDiscographyImage] = useState<
    string | null | undefined
  >(null);

  const [discographyYearError, setDiscographyYearError] = useState<string>("");
  const [discographyLinkError, setDiscographyLinkError] = useState<string>("");
  const [discographyPublisherElError, setDiscographyPublisherElError] =
    useState<string>("");
  const [discographyPublisherEnError, setDiscographyPublisherEnError] =
    useState<string>("");
  const [discographyPublisherDeError, setDiscographyPublisherDeError] =
    useState<string>("");
  const [discographyTitleElError, setDiscographyTitleElError] =
    useState<string>("");
  const [discographyTitleEnError, setDiscographyTitleEnError] =
    useState<string>("");
  const [discographyTitleDeError, setDiscographyTitleDeError] =
    useState<string>("");
  const [discographySubtitleElError, setDiscographySubtitleElError] =
    useState<string>("");
  const [discographySubtitleEnError, setDiscographySubtitleEnError] =
    useState<string>("");
  const [discographySubtitleDeError, setDiscographySubtitleDeError] =
    useState<string>("");
  const [discographyDescriptionElError, setDiscographyDescriptionElError] =
    useState<string>("");
  const [discographyDescriptionEnError, setDiscographyDescriptionEnError] =
    useState<string>("");
  const [discographyDescriptionDeError, setDiscographyDescriptionDeError] =
    useState<string>("");
  const [discographyMediaError, setDiscographyMediaError] =
    useState<string>("");

  const yearErrorMessage = validateDigitText(discographyYear, 4);
  const yearIsValid = yearErrorMessage == null;
  const linkErrorMessage =
    discographyLink && discographyLink.trim().length > 0
      ? validateUrl(discographyLink)
      : null;
  const linkIsValid = linkErrorMessage == null;
  const publisherElErrorMessage = validateText(discographyPublisherEl);
  const publisherElIsValid = publisherElErrorMessage == null;
  const publisherEnErrorMessage = validateText(discographyPublisherEn);
  const publisherEnIsValid = publisherEnErrorMessage == null;
  const publisherDeErrorMessage = validateText(discographyPublisherDe);
  const publisherDeIsValid = publisherDeErrorMessage == null;
  const titleElErrorMessage = validateText(discographyTitleEl);
  const titleElIsValid = titleElErrorMessage == null;
  const titleEnErrorMessage = validateText(discographyTitleEn);
  const titleEnIsValid = titleEnErrorMessage == null;
  const titleDeErrorMessage = validateText(discographyTitleDe);
  const titleDeIsValid = titleDeErrorMessage == null;
  const subtitleElErrorMessage =
    discographySubtitleEl.trim().length > 0
      ? validateText(discographySubtitleEl)
      : null;
  const subtitleElIsValid = subtitleElErrorMessage == null;
  const subtitleEnErrorMessage =
    discographySubtitleEn.trim().length > 0
      ? validateText(discographySubtitleEn)
      : null;
  const subtitleEnIsValid = subtitleEnErrorMessage == null;
  const subtitleDeErrorMessage =
    discographySubtitleDe.trim().length > 0
      ? validateText(discographySubtitleDe)
      : null;
  const subtitleDeIsValid = subtitleDeErrorMessage == null;

  let descriptionElErrorMessage = null;
  if (discographyDescriptionEl && discographyDescriptionEl.length > 0) {
    for (let paragraphInput of discographyDescriptionEl) {
      descriptionElErrorMessage = validateText(paragraphInput);
      if (descriptionElErrorMessage != null) break;
    }
  }
  const descriptionElIsValid = descriptionElErrorMessage == null;

  let descriptionEnErrorMessage = null;
  if (discographyDescriptionEn && discographyDescriptionEn.length > 0) {
    for (let paragraphInput of discographyDescriptionEn) {
      descriptionEnErrorMessage = validateText(paragraphInput);
      if (descriptionEnErrorMessage != null) break;
    }
  }
  const descriptionEnIsValid = descriptionEnErrorMessage == null;

  let descriptionDeErrorMessage = null;
  if (discographyDescriptionDe && discographyDescriptionDe.length > 0) {
    for (let paragraphInput of discographyDescriptionDe) {
      descriptionDeErrorMessage = validateText(paragraphInput);
      if (descriptionDeErrorMessage != null) break;
    }
  }
  const descriptionDeIsValid = descriptionDeErrorMessage == null;

  let mediaErrorMessage = null;
  if (discographyMedia && discographyMedia.length > 0) {
    for (let mediaInput of discographyMedia) {
      mediaErrorMessage = validateUrl(mediaInput);
      if (mediaErrorMessage != null) break;
    }
  }
  const mediaIsValid = mediaErrorMessage == null;

  const formIsValid =
    yearIsValid &&
    linkIsValid &&
    publisherElIsValid &&
    publisherEnIsValid &&
    publisherDeIsValid &&
    titleElIsValid &&
    titleEnIsValid &&
    titleDeIsValid &&
    subtitleElIsValid &&
    subtitleEnIsValid &&
    subtitleDeIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid &&
    mediaIsValid;

  // Get discography's details from API and set state
  const getDiscographyDetails = useCallback(() => {
    // transform API response
    const transformResponse = (response: Discography) => {
      setDiscographyYear(`${response.year}`);
      setDiscographyLink(response.link);
      setDiscographyPublisherEl(response.publisherEl);
      setDiscographyPublisherEn(response.publisherEn);
      setDiscographyPublisherDe(response.publisherDe);
      setDiscographyTitleEl(response.titleEl);
      setDiscographyTitleEn(response.titleEn);
      setDiscographyTitleDe(response.titleDe);
      setDiscographySubtitleEl(response.subtitleEl);
      setDiscographySubtitleEn(response.subtitleEn);
      setDiscographySubtitleDe(response.subtitleDe);
      setDiscographyDescriptionEl(response.descriptionEl);
      setDiscographyDescriptionEn(response.descriptionEn);
      setDiscographyDescriptionDe(response.descriptionDe);
      setDiscographyImage(response.image);
      setDiscographyMedia(response.media);
    };

    // send GET request to API's route /admin/discography/info/:year
    const url = `${process.env.REACT_APP_API_URL}/admin/discography/info/${discographyInitialYear}`;
    sendRequest({ url, method: "GET", token: auth.token }, transformResponse);
  }, [
    discographyInitialYear,
    setDiscographyYear,
    setDiscographyLink,
    setDiscographyPublisherEl,
    setDiscographyPublisherEn,
    setDiscographyPublisherDe,
    setDiscographyTitleEl,
    setDiscographyTitleEn,
    setDiscographyTitleDe,
    setDiscographySubtitleEl,
    setDiscographySubtitleEn,
    setDiscographySubtitleDe,
    setDiscographyDescriptionEl,
    setDiscographyDescriptionEn,
    setDiscographyDescriptionDe,
    setDiscographyMedia,
    setDiscographyImage,
    sendRequest,
    auth.token,
  ]);

  useEffect(() => {
    getDiscographyDetails();
  }, [getDiscographyDetails]);

  const editDiscography = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate("/discography", { replace: true });
    };

    // send PATCH request to API's route /admin/discography/edit/:year
    const url = `${process.env.REACT_APP_API_URL}/admin/discography/edit/${discographyInitialYear}`;
    sendRequest(
      {
        url,
        method: "PATCH",
        token: auth.token,
        data: {
          year: discographyYear.trim(),
          link: discographyLink?.trim(),
          publisherEl: discographyPublisherEl?.trim() || "",
          publisherEn: discographyPublisherEn?.trim() || "",
          publisherDe: discographyPublisherDe?.trim() || "",
          titleEl: discographyTitleEl?.trim() || "",
          titleEn: discographyTitleEn?.trim() || "",
          titleDe: discographyTitleDe?.trim() || "",
          subtitleEl: discographySubtitleEl?.trim() || "",
          subtitleEn: discographySubtitleEn?.trim() || "",
          subtitleDe: discographySubtitleDe?.trim() || "",
          descriptionEl:
            discographyDescriptionEl && discographyDescriptionEl.length > 0
              ? discographyDescriptionEl
              : [],
          descriptionEn:
            discographyDescriptionEn && discographyDescriptionEn.length > 0
              ? discographyDescriptionEn
              : [],
          descriptionDe:
            discographyDescriptionDe && discographyDescriptionDe.length > 0
              ? discographyDescriptionDe
              : [],
          media:
            discographyMedia && discographyMedia.length > 0
              ? JSON.stringify(discographyMedia)
              : JSON.stringify([]),
        },
      },
      transformResponse
    );
  };

  return (
    <>
      <form className={styles["form_container"]} onSubmit={editDiscography}>
        <DiscographyInfoForm
          year={discographyYear}
          setYear={setDiscographyYear}
          yearError={discographyYearError}
          setYearError={setDiscographyYearError}
          yearErrorMessage={yearErrorMessage}
          yearIsValid={yearIsValid}
          link={discographyLink}
          setLink={setDiscographyLink}
          linkError={discographyLinkError}
          setLinkError={setDiscographyLinkError}
          linkErrorMessage={linkErrorMessage}
          linkIsValid={linkIsValid}
          publisherEl={discographyPublisherEl}
          setPublisherEl={setDiscographyPublisherEl}
          publisherElError={discographyPublisherElError}
          setPublisherElError={setDiscographyPublisherElError}
          publisherElErrorMessage={publisherElErrorMessage}
          publisherElIsValid={publisherElIsValid}
          publisherEn={discographyPublisherEn}
          setPublisherEn={setDiscographyPublisherEn}
          publisherEnError={discographyPublisherEnError}
          setPublisherEnError={setDiscographyPublisherEnError}
          publisherEnErrorMessage={publisherEnErrorMessage}
          publisherEnIsValid={publisherEnIsValid}
          publisherDe={discographyPublisherDe}
          setPublisherDe={setDiscographyPublisherDe}
          publisherDeError={discographyPublisherDeError}
          setPublisherDeError={setDiscographyPublisherDeError}
          publisherDeErrorMessage={publisherDeErrorMessage}
          publisherDeIsValid={publisherDeIsValid}
          titleEl={discographyTitleEl}
          setTitleEl={setDiscographyTitleEl}
          titleElError={discographyTitleElError}
          setTitleElError={setDiscographyTitleElError}
          titleElErrorMessage={titleElErrorMessage}
          titleElIsValid={titleElIsValid}
          titleEn={discographyTitleEn}
          setTitleEn={setDiscographyTitleEn}
          titleEnError={discographyTitleEnError}
          setTitleEnError={setDiscographyTitleEnError}
          titleEnErrorMessage={titleEnErrorMessage}
          titleEnIsValid={titleEnIsValid}
          titleDe={discographyTitleDe}
          setTitleDe={setDiscographyTitleDe}
          titleDeError={discographyTitleDeError}
          setTitleDeError={setDiscographyTitleDeError}
          titleDeErrorMessage={titleDeErrorMessage}
          titleDeIsValid={titleDeIsValid}
          subtitleEl={discographySubtitleEl}
          setSubtitleEl={setDiscographySubtitleEl}
          subtitleElError={discographySubtitleElError}
          setSubtitleElError={setDiscographySubtitleElError}
          subtitleElErrorMessage={subtitleElErrorMessage}
          subtitleElIsValid={subtitleElIsValid}
          subtitleEn={discographySubtitleEn}
          setSubtitleEn={setDiscographySubtitleEn}
          subtitleEnError={discographySubtitleEnError}
          setSubtitleEnError={setDiscographySubtitleEnError}
          subtitleEnErrorMessage={subtitleEnErrorMessage}
          subtitleEnIsValid={subtitleEnIsValid}
          subtitleDe={discographySubtitleDe}
          setSubtitleDe={setDiscographySubtitleDe}
          subtitleDeError={discographySubtitleDeError}
          setSubtitleDeError={setDiscographySubtitleDeError}
          subtitleDeErrorMessage={subtitleDeErrorMessage}
          subtitleDeIsValid={subtitleDeIsValid}
          descriptionEl={discographyDescriptionEl}
          setDescriptionEl={setDiscographyDescriptionEl}
          descriptionElError={discographyDescriptionElError}
          setDescriptionElError={setDiscographyDescriptionElError}
          descriptionElErrorMessage={descriptionElErrorMessage}
          descriptionElIsValid={descriptionElIsValid}
          descriptionEn={discographyDescriptionEn}
          setDescriptionEn={setDiscographyDescriptionEn}
          descriptionEnError={discographyDescriptionEnError}
          setDescriptionEnError={setDiscographyDescriptionEnError}
          descriptionEnErrorMessage={descriptionEnErrorMessage}
          descriptionEnIsValid={descriptionEnIsValid}
          descriptionDe={discographyDescriptionDe}
          setDescriptionDe={setDiscographyDescriptionDe}
          descriptionDeError={discographyDescriptionDeError}
          setDescriptionDeError={setDiscographyDescriptionDeError}
          descriptionDeErrorMessage={descriptionDeErrorMessage}
          descriptionDeIsValid={descriptionDeIsValid}
          media={discographyMedia}
          setMedia={setDiscographyMedia}
          mediaError={discographyMediaError}
          setMediaError={setDiscographyMediaError}
          mediaErrorMessage={mediaErrorMessage}
          mediaIsValid={mediaIsValid}
          isLoading={isLoading}
        />
        <div className={styles["form_btn_container"]}>
          <button
            type="submit"
            className={styles["form_submit_btn"]}
            disabled={isLoading || !formIsValid}
          >
            Αποθήκευση Αλλαγών
          </button>
        </div>
        {error && error.trim() !== "" && (
          <p className={styles["form_error"]}>{error}</p>
        )}
      </form>
      <DiscographyImageDisplay
        year={discographyInitialYear || ""}
        image={discographyImage}
        onRefresh={getDiscographyDetails}
      />
      <DiscographyImageForm
        year={discographyInitialYear || ""}
        existingImage={!!discographyImage}
        onRefresh={getDiscographyDetails}
      />
    </>
  );
};

export default EditDiscographyForm;
