import { FC, ChangeEvent, useState, useMemo } from "react";

import { Language } from "../../../interfaces/Language";

import styles from "./VideoInfoForm.module.css";

interface VideoInfoFormProps {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
  linkError: string;
  setLinkError: React.Dispatch<React.SetStateAction<string>>;
  linkErrorMessage: string | null;
  linkIsValid: boolean;
  descriptionEl: string;
  setDescriptionEl: React.Dispatch<React.SetStateAction<string>>;
  descriptionElError: string;
  setDescriptionElError: React.Dispatch<React.SetStateAction<string>>;
  descriptionElErrorMessage: string | null;
  descriptionElIsValid: boolean;
  descriptionEn: string;
  setDescriptionEn: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnError: string;
  setDescriptionEnError: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnErrorMessage: string | null;
  descriptionEnIsValid: boolean;
  descriptionDe: string;
  setDescriptionDe: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeError: string;
  setDescriptionDeError: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeErrorMessage: string | null;
  descriptionDeIsValid: boolean;
  isLoading: boolean;
}

/**
 * Extract the video ID from a youtube video URL
 * @param {string} url The video's full URL
 * @return {string | null} The video's ID, or null if the URL is invalid
 */
const getYouTubeVideoId = (url: string): string | null => {
  // Regular expression pattern to match YouTube video URLs
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([^\s&?\/]+)/;

  // Extract the video ID from the URL using the regex pattern
  const match = url.match(youtubeRegex);

  // Return the extracted video ID or null if the URL is not a valid YouTube video URL
  return match && match[1] ? match[1] : null;
};

const VideoInfoForm: FC<VideoInfoFormProps> = ({
  link,
  setLink,
  linkError,
  setLinkError,
  linkErrorMessage,
  linkIsValid,
  descriptionEl,
  setDescriptionEl,
  descriptionElError,
  setDescriptionElError,
  descriptionElErrorMessage,
  descriptionElIsValid,
  descriptionEn,
  setDescriptionEn,
  descriptionEnError,
  setDescriptionEnError,
  descriptionEnErrorMessage,
  descriptionEnIsValid,
  descriptionDe,
  setDescriptionDe,
  descriptionDeError,
  setDescriptionDeError,
  descriptionDeErrorMessage,
  descriptionDeIsValid,
  isLoading,
}) => {
  const [language, setLanguage] = useState<Language>(Language.GREEK);

  const videoId: string | null = useMemo(() => {
    if (!linkIsValid) return null;
    return getYouTubeVideoId(link);
  }, [link, linkIsValid]);

  const linkChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (linkError !== "") setLinkError("");
    setLink(event.target.value);
  };

  const linkBlurHandler = () => {
    if (!linkIsValid && linkErrorMessage) {
      setLinkError(linkErrorMessage);
    } else {
      setLinkError("");
    }
  };

  const descriptionElChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl(event.target.value);
  };

  const descriptionElBlurHandler = () => {
    if (!descriptionElIsValid && descriptionElErrorMessage) {
      setDescriptionElError(descriptionElErrorMessage);
    } else {
      setDescriptionElError("");
    }
  };

  const descriptionEnChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn(event.target.value);
  };

  const descriptionEnBlurHandler = () => {
    if (!descriptionEnIsValid && descriptionEnErrorMessage) {
      setDescriptionEnError(descriptionEnErrorMessage);
    } else {
      setDescriptionEnError("");
    }
  };

  const descriptionDeChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe(event.target.value);
  };

  const descriptionDeBlurHandler = () => {
    if (!descriptionDeIsValid && descriptionDeErrorMessage) {
      setDescriptionDeError(descriptionDeErrorMessage);
    } else {
      setDescriptionDeError("");
    }
  };

  const languageChangeHandler = (lang: Language) => setLanguage(lang);

  return (
    <>
      {/* Link input */}
      <div className={styles["form_field"]}>
        <label htmlFor="link-input">Link Video στο YouTube</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="link-input"
            type="text"
            placeholder="π.χ. https://www.youtube.com/watch?v=..."
            disabled={isLoading}
            value={link || ""}
            onChange={linkChangeHandler}
            onBlur={linkBlurHandler}
          />
        </div>
        {linkError && linkError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{linkError}</p>
        )}
      </div>

      {linkIsValid &&
        !linkErrorMessage &&
        (videoId ? (
          <div className={styles["video-container"]}>
            <div className={styles["video-wrapper"]}>
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ) : (
          <p className={styles["has-error"]}>
            Το βίντεο δεν αντιστοιχεί σε σύνδεσμο YouTube
          </p>
        ))}

      {/* Select Language */}
      <div className={styles["language-selection"]}>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GREEK)}
          className={`${
            language === Language.GREEK
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionElIsValid && styles["has-error"]}`}
        >
          Ελληνικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.ENGLISH)}
          className={`${
            language === Language.ENGLISH
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionEnIsValid && styles["has-error"]}`}
        >
          Αγγλικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GERMAN)}
          className={`${
            language === Language.GERMAN
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
              ${!descriptionDeIsValid && styles["has-error"]}`}
        >
          Γερμανικά
        </button>
      </div>
      {/* Description Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-el-input">Περιγραφή Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-el-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEl}
              onChange={descriptionElChangeHandler}
              onBlur={descriptionElBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionElError && descriptionElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionElError}</p>
          )}
        </div>
      )}

      {/* Description English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-en-input">Περιγραφή Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-en-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEn}
              onChange={descriptionEnChangeHandler}
              onBlur={descriptionEnBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionEnError && descriptionEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionEnError}</p>
          )}
        </div>
      )}

      {/* Description German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-de-input">Περιγραφή Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-de-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionDe}
              onChange={descriptionDeChangeHandler}
              onBlur={descriptionDeBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionDeError && descriptionDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionDeError}</p>
          )}
        </div>
      )}
    </>
  );
};

export default VideoInfoForm;
