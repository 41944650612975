import { FC, useContext, useEffect, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { AuthContext } from "./context";
import { AuthContextType } from "./interfaces/AuthContext";

import { calculateRemainingTime, setLargeTimeout } from "./utils/time";
import { useHttp } from "./hooks";

import { Layout } from "./components";
import {
  Loading,
  Login,
  // Concerts,
  // Discography,
  // Uom,
  // UomConcert,
  // UomContests,
  // Gallery,
  // Videos,
  // Press,
} from "./pages";

import {
  ConcertsComing,
  ConcertsArchive,
  CreateConcertForm,
  EditConcertForm,
} from "./components/Concerts";

import {
  DiscographyAll,
  AddDiscographyForm,
  EditDiscographyForm,
} from "./components/Discography";

import {
  UomConcertAll,
  AddUomConcertForm,
  EditUomConcertForm,
} from "./components/UomConcerts";

import {
  UomContestsAll,
  AddUomContestForm,
  EditUomContestForm,
  Gallery as UomContestsGallery,
} from "./components/UomContests";

import { VideoAll, AddVideoForm, EditVideoForm } from "./components/Videos";

import {
  // PressAll,
  // AddPressForm,
  // EditPressForm,
  Photos as PressPhotos,
} from "./components/Press";

const Concerts = lazy(() => import("./pages/Concerts/Concerts"));
const Discography = lazy(() => import("./pages/Discography/Discography"));
const Uom = lazy(() => import("./pages/Uom/Uom"));
const UomConcert = lazy(() => import("./pages/UomConcerts/UomConcert"));
const UomContests = lazy(() => import("./pages/UomContests/UomContests"));
const Gallery = lazy(() => import("./pages/Gallery/Gallery"));
const Videos = lazy(() => import("./pages/Videos/Videos"));
const Press = lazy(() => import("./pages/Press/Press"));

interface ApiResponse {
  token: string;
  expiresIn: number;
}

const App: FC = () => {
  const { auth, login, logout } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest, resetState } = useHttp();

  if (!isLoading && error) {
    alert(error);
    resetState();
  }

  // On first run: If admin is has a saved token check validity and refresh token
  useEffect(() => {
    if (!auth.token || !auth.expirationTime) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      login(response.token, response.expiresIn);
    };

    // send GET request to API's route /admin/refresh-token
    const url = `${process.env.REACT_APP_API_URL}/admin/auth/refresh-token`;
    sendRequest({ url, method: "GET", token: auth.token }, transformResponse); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Timer: Automatically logout user after the token has expired
    let autoLogoutTimer: ReturnType<typeof setTimeout> | null = null;

    // if user is logged in and expiration time is set and valid, start the timeout to auto-logout:
    if (auth.isLoggedIn && auth.expirationTime) {
      const remainingTime = calculateRemainingTime(auth.expirationTime);
      if (autoLogoutTimer) clearTimeout(autoLogoutTimer);
      autoLogoutTimer = setLargeTimeout(logout, remainingTime);
    }
    // if user is not logged in or expiration time is invalid, reset the running timer (if any)
    else if (autoLogoutTimer) {
      clearTimeout(autoLogoutTimer);
    }
  }, [auth.isLoggedIn, auth.expirationTime, logout]);

  if (isLoading) {
    return (
      <Layout>
        <Routes>
          <Route path="*" element={<Loading />} />
        </Routes>
      </Layout>
    );
  }

  if (!auth.isLoggedIn) {
    return (
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
      </Layout>
    );
  }

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Concerts page */}
          <Route path="/concerts" element={<Concerts />}>
            <Route path="" element={<ConcertsComing />} />
            <Route path="archive" element={<ConcertsArchive />} />
            <Route path="create" element={<CreateConcertForm />} />
            <Route
              path="edit/:concertInitialDate"
              element={<EditConcertForm />}
            />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          {/* Discography page */}
          <Route path="/discography" element={<Discography />}>
            <Route path="" element={<DiscographyAll />} />
            <Route path="add" element={<AddDiscographyForm />} />
            <Route
              path="edit/:discographyInitialYear"
              element={<EditDiscographyForm />}
            />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          {/* UOM pages */}
          <Route path="/uom" element={<Uom />}>
            {/* UOM Concerts */}
            <Route path="concerts" element={<UomConcert />}>
              <Route path="" element={<UomConcertAll />} />
              <Route path="add" element={<AddUomConcertForm />} />
              <Route path="edit/:concertId" element={<EditUomConcertForm />} />
              <Route path="*" element={<Navigate to="" replace />} />
            </Route>
            {/* UOM Concerts */}
            <Route path="contests" element={<UomContests />}>
              <Route path="" element={<UomContestsAll />} />
              <Route path="add" element={<AddUomContestForm />} />
              <Route path="edit/:contestId" element={<EditUomContestForm />} />
              <Route path="gallery" element={<UomContestsGallery />} />
              <Route path="*" element={<Navigate to="" replace />} />
            </Route>
            <Route path="*" element={<Navigate to="concerts" replace />} />
          </Route>
          {/* Gallery page */}
          <Route path="/gallery" element={<Gallery />} />
          {/* Videos page */}
          <Route path="/videos" element={<Videos />}>
            <Route path="" element={<VideoAll />} />
            <Route path="add" element={<AddVideoForm />} />
            <Route path="edit/:id" element={<EditVideoForm />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          {/* Press */}
          <Route path="press" element={<Press />}>
            {/* <Route path="" element={<PressAll />} /> */}
            {/* <Route path="add" element={<AddPressForm />} /> */}
            {/* <Route path="edit/:pressId" element={<EditPressForm />} /> */}
            <Route path="photos" element={<PressPhotos />} />
            {/* <Route path="*" element={<Navigate to="" replace />} /> */}
            <Route path="" element={<Navigate to="photos" replace />} />
            <Route path="*" element={<Navigate to="photos" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="concerts" replace />} />
          <Route path="*" element={<Navigate to="/concerts" replace />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
