import { FC } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import styles from "./Press.module.css";

enum ActiveLink {
  // PressAll = 0,
  PressImages = 1,
  // AddPress = 2,
  // EditPress = 3,
}

const Press: FC = () => {
  let selected: ActiveLink | null;
  const location = useLocation();
  switch (location.pathname) {
    // case "/press/add":
    //   selected = ActiveLink.AddPress;
    //   break;
    case "/press/photos":
      selected = ActiveLink.PressImages;
      break;
    // case "/press":
    //   selected = ActiveLink.PressAll;
    //   break;
    default:
      // selected = ActiveLink.EditPress;
      selected = ActiveLink.PressImages;
      break;
  }

  return (
    <>
      <nav className={styles["press-navigation"]}>
        {/* <Link
          to=""
          className={
            selected === ActiveLink.PressAll
              ? styles["press-navigation-link_active"]
              : styles["press-navigation-link_inactive"]
          }
        >
          Προβολή
        </Link> */}
        <Link
          to="photos"
          className={
            selected === ActiveLink.PressImages
              ? styles["press-navigation-link_active"]
              : styles["press-navigation-link_inactive"]
          }
        >
          Εικόνες
        </Link>
        {/* <Link
          to="add"
          className={
            selected === ActiveLink.AddPress
              ? styles["press-navigation-link_active"]
              : styles["press-navigation-link_inactive"]
          }
        >
          Δημιουργία
        </Link>
        <Link
          to="edit"
          aria-disabled
          onClick={(e) => e.preventDefault()}
          className={`${
            selected === ActiveLink.EditPress
              ? styles["press-navigation-link_active"]
              : styles["press-navigation-link_inactive"]
          } ${styles["press-navigation-link_disabled"]}`}
        >
          Επεξεργασία
        </Link> */}
      </nav>
      <Outlet />
    </>
  );
};

export default Press;
