import { FC, ChangeEvent, useState } from "react";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";

import { Language } from "../../../interfaces/Language";

import styles from "./UomConcertInfoForm.module.css";

interface UomConcertInfoFormProps {
  dateFrom: string;
  setDateFrom: React.Dispatch<React.SetStateAction<string>>;
  dateFromError: string;
  setDateFromError: React.Dispatch<React.SetStateAction<string>>;
  dateFromErrorMessage: string | null;
  dateFromIsValid: boolean;
  dateTo: string;
  setDateTo: React.Dispatch<React.SetStateAction<string>>;
  dateToError: string;
  setDateToError: React.Dispatch<React.SetStateAction<string>>;
  dateToErrorMessage: string | null;
  dateToIsValid: boolean;
  descriptionEl: string;
  setDescriptionEl: React.Dispatch<React.SetStateAction<string>>;
  descriptionElError: string;
  setDescriptionElError: React.Dispatch<React.SetStateAction<string>>;
  descriptionElErrorMessage: string | null;
  descriptionElIsValid: boolean;
  descriptionEn: string;
  setDescriptionEn: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnError: string;
  setDescriptionEnError: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnErrorMessage: string | null;
  descriptionEnIsValid: boolean;
  descriptionDe: string;
  setDescriptionDe: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeError: string;
  setDescriptionDeError: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeErrorMessage: string | null;
  descriptionDeIsValid: boolean;
  media: string[] | null;
  setMedia: React.Dispatch<React.SetStateAction<string[] | null>>;
  mediaError: string;
  setMediaError: React.Dispatch<React.SetStateAction<string>>;
  mediaErrorMessage: string | null;
  mediaIsValid: boolean;
  isRecital: boolean;
  setIsRecital: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const UomConcertInfoForm: FC<UomConcertInfoFormProps> = ({
  dateFrom,
  setDateFrom,
  dateFromError,
  setDateFromError,
  dateFromErrorMessage,
  dateFromIsValid,
  dateTo,
  setDateTo,
  dateToError,
  setDateToError,
  dateToErrorMessage,
  dateToIsValid,
  descriptionEl,
  setDescriptionEl,
  descriptionElError,
  setDescriptionElError,
  descriptionElErrorMessage,
  descriptionElIsValid,
  descriptionEn,
  setDescriptionEn,
  descriptionEnError,
  setDescriptionEnError,
  descriptionEnErrorMessage,
  descriptionEnIsValid,
  descriptionDe,
  setDescriptionDe,
  descriptionDeError,
  setDescriptionDeError,
  descriptionDeErrorMessage,
  descriptionDeIsValid,
  media,
  setMedia,
  mediaError,
  setMediaError,
  mediaErrorMessage,
  mediaIsValid,
  isRecital,
  setIsRecital,
  isLoading,
}) => {
  const [language, setLanguage] = useState<Language>(Language.GREEK);

  const dateFromChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (dateFromError !== "") setDateFromError("");
    setDateFrom(event.target.value);
  };

  const dateFromBlurHandler = () => {
    if (!dateFromIsValid && dateFromErrorMessage) {
      setDateFromError(dateFromErrorMessage);
    } else {
      setDateFromError("");
    }
  };

  const dateToChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (dateToError !== "") setDateToError("");
    setDateTo(event.target.value);
  };

  const dateToBlurHandler = () => {
    if (!dateToIsValid && dateToErrorMessage) {
      setDateToError(dateToErrorMessage);
    } else {
      setDateToError("");
    }
  };

  const descriptionElChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl(event.target.value);
  };

  const descriptionElBlurHandler = () => {
    if (!descriptionElIsValid && descriptionElErrorMessage) {
      setDescriptionElError(descriptionElErrorMessage);
    } else {
      setDescriptionElError("");
    }
  };

  const descriptionEnChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn(event.target.value);
  };

  const descriptionEnBlurHandler = () => {
    if (!descriptionEnIsValid && descriptionEnErrorMessage) {
      setDescriptionEnError(descriptionEnErrorMessage);
    } else {
      setDescriptionEnError("");
    }
  };

  const descriptionDeChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe(event.target.value);
  };

  const descriptionDeBlurHandler = () => {
    if (!descriptionDeIsValid && descriptionDeErrorMessage) {
      setDescriptionDeError(descriptionDeErrorMessage);
    } else {
      setDescriptionDeError("");
    }
  };

  const mediaAddHandler = () => {
    setMedia((current) => {
      if (!current) return [""];
      const newMedia = current.slice();
      newMedia.push("");
      return newMedia;
    });
  };

  const mediaRemoveHandler = (idx: number) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1);
      return newMedia;
    });
  };

  const mediaChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1, event.target.value);
      return newMedia;
    });
  };

  const mediaBlurHandler = () => {
    if (!mediaIsValid && mediaErrorMessage) {
      setMediaError(mediaErrorMessage);
    } else {
      setMediaError("");
    }
  };

  const languageChangeHandler = (lang: Language) => setLanguage(lang);

  return (
    <>
      {/* Date From input */}
      <div className={styles["form_field"]}>
        <label htmlFor="date-from-input">Ημερομηνία Συναυλίας (από)</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="date-from-input"
            type="date"
            disabled={isLoading}
            value={dateFrom}
            onChange={dateFromChangeHandler}
            onBlur={dateFromBlurHandler}
            required
            aria-required
          />
        </div>
        {dateFromError && dateFromError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{dateFromError}</p>
        )}
      </div>

      {/* Date To input */}
      <div className={styles["form_field"]}>
        <label htmlFor="date-to-input">
          Ημερομηνία Συναυλίας (μέχρι) (βάλτε την ίδια ημερομηνία αν πρόκειται
          για μονοήμερη συναυλία)
        </label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="date-to-input"
            type="date"
            disabled={isLoading}
            value={dateTo}
            onChange={dateToChangeHandler}
            onBlur={dateToBlurHandler}
            required
            aria-required
          />
        </div>
        {dateToError && dateToError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{dateToError}</p>
        )}
      </div>

      {/* Is Recital */}
      <div className={`${styles["form_field"]} ${styles["form_checkbox"]}`}>
        <div
          className={
            isLoading
              ? styles["form_field_checkbox-disabled"]
              : styles["form_field_checkbox"]
          }
        >
          <input
            id="is-recital-input"
            type="checkbox"
            disabled={isLoading}
            checked={isRecital}
            onChange={() => setIsRecital((prev) => !prev)}
          />
        </div>
        <label htmlFor="is-recital-input">Πτυχιακό Ρεσιτάλ</label>
      </div>

      {/* Select Language */}
      <div className={styles["language-selection"]}>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GREEK)}
          className={`${
            language === Language.GREEK
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionElIsValid && styles["has-error"]}`}
        >
          Ελληνικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.ENGLISH)}
          className={`${
            language === Language.ENGLISH
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionEnIsValid && styles["has-error"]}`}
        >
          Αγγλικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GERMAN)}
          className={`${
            language === Language.GERMAN
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
              ${!descriptionDeIsValid && styles["has-error"]}`}
        >
          Γερμανικά
        </button>
      </div>

      {/* Description Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-el-input">Περιγραφή Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-el-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEl}
              onChange={descriptionElChangeHandler}
              onBlur={descriptionElBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionElError && descriptionElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionElError}</p>
          )}
        </div>
      )}

      {/* Description English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-en-input">Περιγραφή Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-en-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEn}
              onChange={descriptionEnChangeHandler}
              onBlur={descriptionEnBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionEnError && descriptionEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionEnError}</p>
          )}
        </div>
      )}

      {/* Description German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-de-input">Περιγραφή Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-de-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionDe}
              onChange={descriptionDeChangeHandler}
              onBlur={descriptionDeBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionDeError && descriptionDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionDeError}</p>
          )}
        </div>
      )}

      {/* Media input */}
      <div className={styles["form_field"]}>
        <label htmlFor="media-input">Media Συναυλίας (προαιρετικό)</label>
        {media &&
          media.length > 0 &&
          media.map((m, idx) => (
            <div
              key={`media-${idx}`}
              className={
                isLoading
                  ? styles["form_field_input-disabled"]
                  : styles["form_field_input"]
              }
            >
              <input
                id={`media-input-${idx}`}
                type="text"
                placeholder="π.χ. https://www.youtube.com/..."
                disabled={isLoading}
                value={m}
                onChange={(e) => mediaChangeHandler(e, idx)}
                onBlur={mediaBlurHandler}
                required
                aria-required
              />
              <span className={styles["form_field_input-icon_end"]}>
                <AiFillDelete onClick={() => mediaRemoveHandler(idx)} />
              </span>
            </div>
          ))}
        <button
          type="button"
          onClick={mediaAddHandler}
          disabled={isLoading}
          className={
            isLoading
              ? styles["media-add-button-disabled"]
              : styles["media-add-button"]
          }
        >
          Προσθέστε links για περισσότερα Media
          <span className={styles["form_field_input-icon_end"]}>
            <AiFillPlusCircle />
          </span>
        </button>
        {mediaError && mediaError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{mediaError}</p>
        )}
      </div>
    </>
  );
};

export default UomConcertInfoForm;
