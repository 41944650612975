import { FC, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { ConcertBrief } from "../../../interfaces/Concert";
import { ModalDialog, ModalDialogActionStyle } from "../..";

import styles from "./ConcertCard.module.css";

interface ConcertCardProps {
  concert: ConcertBrief;
  onDelete: (date: string) => void;
  onEdit: (date: string) => void;
  isLoading: boolean;
}

const ConcertCard: FC<ConcertCardProps> = ({
  concert,
  onDelete,
  onEdit,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dateText = new Date(concert.date).toLocaleDateString("el", dateOptions);

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(concert.date);
    onCancelAction();
  };

  return (
    <div className={styles["concert-container"]}>
      <div className={styles["concert-info"]}>
        <p>{dateText}</p>
        <p>{concert.location}</p>
      </div>
      <div className={styles["concert-actions"]}>
        <AiFillEdit onClick={() => onEdit(concert.date)} />
        <AiFillDelete onClick={onShowDeleteDialog} />
      </div>
      {showDeleteDialog && (
        <ModalDialog
          title="Διαγραφή συναυλίας"
          content={`Είστε σίγουροι ότι θέλετε να διαγράψετε τη συναυλία στις ${concert.date};`}
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: onConfirmAction,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </div>
  );
};

export default ConcertCard;
