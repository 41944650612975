import { FC, useState, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { ModalDialog, ModalDialogActionStyle } from "../..";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import styles from "./UomConcertPdfDisplay.module.css";

interface UomConcertPdfDisplayProps {
  id: string;
  file: string | null | undefined;
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const UomConcertPdfDisplay: FC<UomConcertPdfDisplayProps> = ({
  id,
  file,
  onRefresh,
}) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  // useHttp custom hook
  const { error, sendRequest } = useHttp();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  if (!file) return <></>;

  const deleteDiscographyFile = (): void => {
    if (!file || !showDelete) {
      onCancelAction();
      return;
    }

    onCancelAction();
    if (!id) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    // send DELETE request to API's route /admin/uom/concert/delete-file/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/concert/delete-file/${id}`;
    sendRequest(
      {
        url,
        method: "DELETE",
        token: auth.token,
      },
      transformResponse
    );
  };

  const onCancelAction = () => {
    setShowDelete(false);
  };

  return (
    <>
      <h2 className={styles["files-title"]}>PDF Συναυλίας</h2>
      {error && error.trim() !== "" && (
        <p className={styles["files-error"]}>{error}</p>
      )}
      <div className={styles["container"]}>
        <div
          className={styles["file-container"]}
          onClick={() => setShowDelete(true)}
        >
          <Document
            file={`${process.env.REACT_APP_BASE_URL}/files/uom-concerts/${file}`}
          >
            <Page pageNumber={1} />
          </Document>
        </div>
      </div>
      {showDelete && (
        <ModalDialog
          title="Διαγραφή Αρχείου"
          content="Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο PDF; Προσοχή, η ενέργεια δε μπορεί να αναιρεθεί."
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: deleteDiscographyFile,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </>
  );
};

export default UomConcertPdfDisplay;
