import { FC, useState, useRef } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";

import { ModalDialog, ModalDialogActionStyle } from "../..";
import { ItemTypes } from "../ItemTypes";

import styles from "./GalleryCard.module.css";

interface GalleryCardProps {
  index: number;
  order: number;
  image: string;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  onDelete: (order: number) => void;
  isLoading: boolean;
}

interface DragItem {
  type: string;
  id: string;
  index: number;
}

const GalleryCard: FC<GalleryCardProps> = ({
  index,
  order,
  image,
  moveImage,
  onDelete,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.CARD,
      id: image,
      index,
    } as DragItem,
    type: ItemTypes.CARD,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: true,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height/width
      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(order);
    onCancelAction();
  };

  drag(drop(ref));
  return (
    <div ref={ref}>
      <div
        className={styles["gallery-container"]}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <div className={styles["gallery-image"]}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}/images/gallery/thumb-${image}`}
            alt="Εικόνα Gallery"
          />
        </div>
        <div className={styles["gallery-actions"]}>
          <AiFillDelete onClick={onShowDeleteDialog} />
        </div>
        {showDeleteDialog && (
          <ModalDialog
            title="Διαγραφή Εικόνας"
            content={`Είστε σίγουροι ότι θέλετε να διαγράψετε την εικόνα;`}
            actions={[
              {
                text: "Διαγραφή",
                style: ModalDialogActionStyle.Error,
                onClick: onConfirmAction,
              },
              {
                text: "Ακύρωση",
                style: ModalDialogActionStyle.Secondary,
                onClick: onCancelAction,
              },
            ]}
            onClose={onCancelAction}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryCard;
