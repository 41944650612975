import { FC, useContext, useEffect } from "react";
import classNames from "classnames";
import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { GalleryCard } from "..";

import styles from "./GalleryGrid.module.css";

interface GalleryGridProps {
  images: string[];
  onRefresh: () => void;
}

const GalleryGrid: FC<GalleryGridProps> = ({ images, onRefresh }) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  // handle request to delete an image
  const deleteHandler = (name: string) => {
    // transform API response
    const transformResponse = () => onRefresh();

    const url = `${process.env.REACT_APP_API_URL}/admin/uom/contest/delete-image/${name}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {images && images.length > 0 && (
        <div className={classNames(styles["gallery-grid"])}>
          {images.map((img, index) => (
            <GalleryCard
              key={`i-${img}`}
              image={img}
              onDelete={deleteHandler}
              isLoading={isLoading}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GalleryGrid;
