import { FC, useState } from "react";

import { ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa";

import { ModalImageGallery, ModalImageGalleryActionStyle } from "..";

import styles from "./UomContestImageForm.module.css";

interface UomContestImageFormProps {
  images: string[];
  image: string | null | undefined;
  onSetImage: (image: string | null) => void;
  existingImage: boolean;
  isLoading: boolean;
}

const UomContestImageForm: FC<UomContestImageFormProps> = ({
  images,
  image,
  onSetImage,
  existingImage,
  isLoading,
}) => {
  const [showGalleryDialog, setShowGalleryDialog] = useState<boolean>(false);

  const onShowGalleryDialog = () => {
    if (isLoading) return;
    setShowGalleryDialog(true);
  };

  const onCancelAction = () => {
    setShowGalleryDialog(false);
  };

  const onConfirmAction = (image: string) => {
    onSetImage(image);
    onCancelAction();
  };

  const onDeleteSelection = () => onSetImage(null);

  return (
    <>
      <div className={styles["form_field"]}>
        <label htmlFor="image-input">
          Επιλέξτε Εικόνα
          {existingImage && (
            <>
              <br />
              Σβήστε την υπάρχουσα ή την επιλεγμένη εικόνα για να προσθέσετε
              άλλη
            </>
          )}
        </label>
        <div className={styles["form_images"]}>
          {existingImage && image?.trim() !== "" ? (
            <div
              className={styles["form_field_image"]}
              onClick={onDeleteSelection}
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/uom-contests/${image}`}
                alt="Επιλεγμένη εικόνα"
              />
              <ImCross className={styles["form_field_image-icon"]} />
            </div>
          ) : (
            <div
              className={styles["form_field_image"]}
              onClick={onShowGalleryDialog}
            >
              <FaPlus />
              <p>Επιλέξτε εικόνα</p>
            </div>
          )}
        </div>
      </div>
      {showGalleryDialog && (
        <ModalImageGallery
          title="Επιλογή Εικόνας"
          images={images}
          onSelectImage={onConfirmAction}
          actions={[
            {
              text: "Ακύρωση",
              style: ModalImageGalleryActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </>
  );
};

export default UomContestImageForm;
