import {
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  FormEvent,
} from "react";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { validateText, validateUrl } from "../../../utils/input";

import { VideoInfoForm, VideoImageForm, VideoImageDisplay } from "../index";

import { IVideo } from "../../../interfaces/IVideo";

import styles from "./EditVideoForm.module.css";

interface ApiResponse {
  message: string;
}

const EditVideoForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;
  const params = useParams();
  const { id } = params;

  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  if (!id || id.trim() === "") navigate("/videos", { replace: true });

  const [videoLink, setVideoLink] = useState<string>("");
  const [videoDescriptionEl, setVideoDescriptionEl] = useState<string>("");
  const [videoDescriptionEn, setVideoDescriptionEn] = useState<string>("");
  const [videoDescriptionDe, setVideoDescriptionDe] = useState<string>("");

  const [videoImage, setVideoImage] = useState<string | null | undefined>(null);

  const [videoLinkError, setVideoLinkError] = useState<string>("");
  const [videoDescriptionElError, setVideoDescriptionElError] =
    useState<string>("");
  const [videoDescriptionEnError, setVideoDescriptionEnError] =
    useState<string>("");
  const [videoDescriptionDeError, setVideoDescriptionDeError] =
    useState<string>("");

  const linkErrorMessage = validateUrl(videoLink);
  const linkIsValid = linkErrorMessage == null;

  let descriptionElErrorMessage = null;
  if (videoDescriptionEl && videoDescriptionEl.length > 0) {
    descriptionElErrorMessage = validateText(videoDescriptionEl);
  }
  const descriptionElIsValid = descriptionElErrorMessage == null;

  let descriptionEnErrorMessage = null;
  if (videoDescriptionEn && videoDescriptionEn.length > 0) {
    descriptionEnErrorMessage = validateText(videoDescriptionEn);
  }
  const descriptionEnIsValid = descriptionEnErrorMessage == null;

  let descriptionDeErrorMessage = null;
  if (videoDescriptionDe && videoDescriptionDe.length > 0) {
    descriptionDeErrorMessage = validateText(videoDescriptionDe);
  }
  const descriptionDeIsValid = descriptionDeErrorMessage == null;

  const formIsValid =
    linkIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid;

  // Get video's details from API and set state
  const getVideoDetails = useCallback(() => {
    // transform API response
    const transformResponse = (response: IVideo) => {
      setVideoLink(response.link);
      setVideoDescriptionEl(response.descriptionEl);
      setVideoDescriptionEn(response.descriptionEn);
      setVideoDescriptionDe(response.descriptionDe);
      setVideoImage(response.image);
    };

    // send GET request to API's route /admin/videos/info/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/videos/info/${id}`;
    sendRequest({ url, method: "GET", token: auth.token }, transformResponse);
  }, [
    id,
    setVideoLink,
    setVideoDescriptionEl,
    setVideoDescriptionEn,
    setVideoDescriptionDe,
    setVideoImage,
    sendRequest,
    auth.token,
  ]);

  useEffect(() => {
    getVideoDetails();
  }, [getVideoDetails]);

  const editVideo = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate("/videos", { replace: true });
    };

    // send PATCH request to API's route /admin/videos/edit/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/videos/edit/${id}`;
    sendRequest(
      {
        url,
        method: "PATCH",
        token: auth.token,
        data: {
          link: videoLink?.trim(),
          descriptionEl:
            videoDescriptionEl && videoDescriptionEl.length > 0
              ? videoDescriptionEl
              : [],
          descriptionEn:
            videoDescriptionEn && videoDescriptionEn.length > 0
              ? videoDescriptionEn
              : [],
          descriptionDe:
            videoDescriptionDe && videoDescriptionDe.length > 0
              ? videoDescriptionDe
              : [],
        },
      },
      transformResponse
    );
  };

  return (
    <>
      <form className={styles["form_container"]} onSubmit={editVideo}>
        <VideoInfoForm
          link={videoLink}
          setLink={setVideoLink}
          linkError={videoLinkError}
          setLinkError={setVideoLinkError}
          linkErrorMessage={linkErrorMessage}
          linkIsValid={linkIsValid}
          descriptionEl={videoDescriptionEl}
          setDescriptionEl={setVideoDescriptionEl}
          descriptionElError={videoDescriptionElError}
          setDescriptionElError={setVideoDescriptionElError}
          descriptionElErrorMessage={descriptionElErrorMessage}
          descriptionElIsValid={descriptionElIsValid}
          descriptionEn={videoDescriptionEn}
          setDescriptionEn={setVideoDescriptionEn}
          descriptionEnError={videoDescriptionEnError}
          setDescriptionEnError={setVideoDescriptionEnError}
          descriptionEnErrorMessage={descriptionEnErrorMessage}
          descriptionEnIsValid={descriptionEnIsValid}
          descriptionDe={videoDescriptionDe}
          setDescriptionDe={setVideoDescriptionDe}
          descriptionDeError={videoDescriptionDeError}
          setDescriptionDeError={setVideoDescriptionDeError}
          descriptionDeErrorMessage={descriptionDeErrorMessage}
          descriptionDeIsValid={descriptionDeIsValid}
          isLoading={isLoading}
        />
        <div className={styles["form_btn_container"]}>
          <button
            type="submit"
            className={styles["form_submit_btn"]}
            disabled={isLoading || !formIsValid}
          >
            Αποθήκευση Αλλαγών
          </button>
        </div>
        {error && error.trim() !== "" && (
          <p className={styles["form_error"]}>{error}</p>
        )}
      </form>
      <VideoImageDisplay
        id={id || ""}
        image={videoImage}
        onRefresh={getVideoDetails}
      />
      <VideoImageForm
        id={id || ""}
        existingImage={!!videoImage}
        onRefresh={getVideoDetails}
      />
    </>
  );
};

export default EditVideoForm;
