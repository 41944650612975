export enum Language {
  GREEK = "el",
  ENGLISH = "en",
  GERMAN = "de",
}

/**
 * Check if a given string is a valid language
 * @param {string} lang The string to be checked against available languages
 * @returns true or false, depending on the validity of the string
 */
export const isValidLanguage = (lang: string): boolean => {
  if (!lang) return false;
  return (
    lang === Language.GREEK ||
    lang === Language.ENGLISH ||
    lang === Language.GERMAN
  );
};
