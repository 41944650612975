import { FC, useState, useContext, FormEvent } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { validateText, validateUrl } from "../../../utils/input";

import { VideoInfoForm } from "../index";

import styles from "./AddVideoForm.module.css";

interface ApiResponse {
  message: string;
  id: string;
}

const AddVideoForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;

  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  const [videoLink, setVideoLink] = useState<string>("");
  const [videoDescriptionEl, setVideoDescriptionEl] = useState<string>("");
  const [videoDescriptionEn, setVideoDescriptionEn] = useState<string>("");
  const [videoDescriptionDe, setVideoDescriptionDe] = useState<string>("");

  const [videoLinkError, setVideoLinkError] = useState<string>("");
  const [videoDescriptionElError, setVideoDescriptionElError] =
    useState<string>("");
  const [videoDescriptionEnError, setVideoDescriptionEnError] =
    useState<string>("");
  const [videoDescriptionDeError, setVideoDescriptionDeError] =
    useState<string>("");

  const linkErrorMessage = validateUrl(videoLink);
  const linkIsValid = linkErrorMessage == null;

  let descriptionElErrorMessage = null;
  if (videoDescriptionEl && videoDescriptionEl.length > 0) {
    descriptionElErrorMessage = validateText(videoDescriptionEl);
  }
  const descriptionElIsValid = descriptionElErrorMessage == null;

  let descriptionEnErrorMessage = null;
  if (videoDescriptionEn && videoDescriptionEn.length > 0) {
    descriptionEnErrorMessage = validateText(videoDescriptionEn);
  }
  const descriptionEnIsValid = descriptionEnErrorMessage == null;

  let descriptionDeErrorMessage = null;
  if (videoDescriptionDe && videoDescriptionDe.length > 0) {
    descriptionDeErrorMessage = validateText(videoDescriptionDe);
  }
  const descriptionDeIsValid = descriptionDeErrorMessage == null;

  const formIsValid =
    linkIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid;

  const createVideo = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate(`/videos/edit/${response.id}`, { replace: true });
    };

    // send POST request to API's route /admin/videos/add
    const url = `${process.env.REACT_APP_API_URL}/admin/videos/add`;
    sendRequest(
      {
        url,
        method: "POST",
        token: auth.token,
        data: {
          link: videoLink?.trim(),
          descriptionEl:
            videoDescriptionEl && videoDescriptionEl.length > 0
              ? videoDescriptionEl
              : [],
          descriptionEn:
            videoDescriptionEn && videoDescriptionEn.length > 0
              ? videoDescriptionEn
              : [],
          descriptionDe:
            videoDescriptionDe && videoDescriptionDe.length > 0
              ? videoDescriptionDe
              : [],
        },
      },
      transformResponse
    );
  };

  return (
    <form className={styles["form_container"]} onSubmit={createVideo}>
      <VideoInfoForm
        link={videoLink}
        setLink={setVideoLink}
        linkError={videoLinkError}
        setLinkError={setVideoLinkError}
        linkErrorMessage={linkErrorMessage}
        linkIsValid={linkIsValid}
        descriptionEl={videoDescriptionEl}
        setDescriptionEl={setVideoDescriptionEl}
        descriptionElError={videoDescriptionElError}
        setDescriptionElError={setVideoDescriptionElError}
        descriptionElErrorMessage={descriptionElErrorMessage}
        descriptionElIsValid={descriptionElIsValid}
        descriptionEn={videoDescriptionEn}
        setDescriptionEn={setVideoDescriptionEn}
        descriptionEnError={videoDescriptionEnError}
        setDescriptionEnError={setVideoDescriptionEnError}
        descriptionEnErrorMessage={descriptionEnErrorMessage}
        descriptionEnIsValid={descriptionEnIsValid}
        descriptionDe={videoDescriptionDe}
        setDescriptionDe={setVideoDescriptionDe}
        descriptionDeError={videoDescriptionDeError}
        setDescriptionDeError={setVideoDescriptionDeError}
        descriptionDeErrorMessage={descriptionDeErrorMessage}
        descriptionDeIsValid={descriptionDeIsValid}
        isLoading={isLoading}
      />
      <div className={styles["form_btn_container"]}>
        <button
          type="submit"
          className={styles["form_submit_btn"]}
          disabled={isLoading || !formIsValid}
        >
          Δημιουργία Video
        </button>
      </div>
      {error && error.trim() !== "" && (
        <p className={styles["form_error"]}>{error}</p>
      )}
    </form>
  );
};

export default AddVideoForm;
