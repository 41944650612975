/**
 * Check if date field has a valid form
 * @param {string} date The date to be tested
 * @returns {string | null}, depending on verification success (if not null, value contains the failure message)
 */
export const validateDate = (date: string | null): string | null => {
  if (!date || date.trim().length === 0)
    return "Το πεδίο δε μπορεί να είναι κενό";

  if (isNaN(Date.parse(date))) return "Μη έγκυρη μορφή ημερομηνίας";

  return null;
};
