import { FC, useState, useContext, FormEvent } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import {
  validateText,
  validateDate,
  validateTime,
  validateUrl,
} from "../../../utils/input";

import { ConcertInfoForm } from "../index";

import styles from "./CreateConcertForm.module.css";

interface ApiResponse {
  message: string;
  id: number;
}

const CreateConcertForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;
  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  const [concertDate, setConcertDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [concertTime, setConcertTime] = useState<string>("--:--");
  const [concertLocationEl, setConcertLocationEl] = useState<string>("");
  const [concertLocationEn, setConcertLocationEn] = useState<string>("");
  const [concertLocationDe, setConcertLocationDe] = useState<string>("");
  const [concertDescriptionEl, setConcertDescriptionEl] = useState<
    string | undefined
  >("");
  const [concertDescriptionEn, setConcertDescriptionEn] = useState<
    string | undefined
  >("");
  const [concertDescriptionDe, setConcertDescriptionDe] = useState<
    string | undefined
  >("");
  const [concertLink, setConcertLink] = useState<string | null>("");
  const [concertMedia, setConcertMedia] = useState<string[] | null>([]);

  const [concertDateError, setConcertDateError] = useState<string>("");
  const [concertTimeError, setConcertTimeError] = useState<string>("");
  const [concertLocationElError, setConcertLocationElError] =
    useState<string>("");
  const [concertLocationEnError, setConcertLocationEnError] =
    useState<string>("");
  const [concertLocationDeError, setConcertLocationDeError] =
    useState<string>("");
  const [concertDescriptionElError, setConcertDescriptionElError] =
    useState<string>("");
  const [concertDescriptionEnError, setConcertDescriptionEnError] =
    useState<string>("");
  const [concertDescriptionDeError, setConcertDescriptionDeError] =
    useState<string>("");
  const [concertLinkError, setConcertLinkError] = useState<string>("");
  const [concertMediaError, setConcertMediaError] = useState<string>("");

  const dateErrorMessage = validateDate(concertDate);
  const dateIsValid = dateErrorMessage == null;
  const timeErrorMessage = validateTime(concertTime);
  const timeIsValid = timeErrorMessage == null;
  const locationElErrorMessage = validateText(concertLocationEl, 200);
  const locationElIsValid = locationElErrorMessage == null;
  const locationEnErrorMessage = validateText(concertLocationEn, 200);
  const locationEnIsValid = locationEnErrorMessage == null;
  const locationDeErrorMessage = validateText(concertLocationDe, 200);
  const locationDeIsValid = locationDeErrorMessage == null;
  const descriptionElErrorMessage =
    !concertDescriptionEl || concertDescriptionEl === ""
      ? null
      : validateText(concertDescriptionEl || "", 5000);
  const descriptionElIsValid = descriptionElErrorMessage == null;
  const descriptionEnErrorMessage =
    !concertDescriptionEn || concertDescriptionEn === ""
      ? null
      : validateText(concertDescriptionEn || "", 5000);
  const descriptionEnIsValid = descriptionEnErrorMessage == null;
  const descriptionDeErrorMessage =
    !concertDescriptionDe || concertDescriptionDe === ""
      ? null
      : validateText(concertDescriptionDe || "", 5000);
  const descriptionDeIsValid = descriptionDeErrorMessage == null;
  const linkErrorMessage =
    concertLink && concertLink.trim().length > 0
      ? validateUrl(concertLink)
      : null;
  const linkIsValid = linkErrorMessage == null;

  let mediaErrorMessage = null;
  if (concertMedia && concertMedia.length > 0) {
    for (let mediaInput of concertMedia) {
      mediaErrorMessage = validateUrl(mediaInput);
      if (mediaErrorMessage != null) break;
    }
  }
  const mediaIsValid = mediaErrorMessage == null;

  const formIsValid =
    dateIsValid &&
    locationElIsValid &&
    locationEnIsValid &&
    locationDeIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid &&
    linkIsValid &&
    mediaIsValid;

  const createConcert = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate("/menu", { replace: true });
    };

    // send POST request to API's route /admin/concert/create
    const url = `${process.env.REACT_APP_API_URL}/admin/concert/create`;
    sendRequest(
      {
        url,
        method: "POST",
        token: auth.token,
        data: {
          date: concertDate.trim(),
          time: concertTime.trim(),
          locationGreek: concertLocationEl?.trim() || "",
          locationEnglish: concertLocationEn?.trim() || "",
          locationGerman: concertLocationDe?.trim() || "",
          descriptionGreek: concertDescriptionEl?.trim(),
          descriptionEnglish: concertDescriptionEn?.trim(),
          descriptionGerman: concertDescriptionDe?.trim(),
          link: concertLink?.trim(),
          media:
            concertMedia && concertMedia.length > 0
              ? JSON.stringify(concertMedia)
              : JSON.stringify([]),
        },
      },
      transformResponse
    );
  };

  return (
    <form className={styles["form_container"]} onSubmit={createConcert}>
      <ConcertInfoForm
        date={concertDate}
        setDate={setConcertDate}
        dateError={concertDateError}
        setDateError={setConcertDateError}
        dateErrorMessage={dateErrorMessage}
        dateIsValid={dateIsValid}
        time={concertTime}
        setTime={setConcertTime}
        timeError={concertTimeError}
        setTimeError={setConcertTimeError}
        timeErrorMessage={timeErrorMessage}
        timeIsValid={timeIsValid}
        locationEl={concertLocationEl}
        setLocationEl={setConcertLocationEl}
        locationElError={concertLocationElError}
        setLocationElError={setConcertLocationElError}
        locationElErrorMessage={locationElErrorMessage}
        locationElIsValid={locationElIsValid}
        locationEn={concertLocationEn}
        setLocationEn={setConcertLocationEn}
        locationEnError={concertLocationEnError}
        setLocationEnError={setConcertLocationEnError}
        locationEnErrorMessage={locationEnErrorMessage}
        locationEnIsValid={locationEnIsValid}
        locationDe={concertLocationDe}
        setLocationDe={setConcertLocationDe}
        locationDeError={concertLocationDeError}
        setLocationDeError={setConcertLocationDeError}
        locationDeErrorMessage={locationDeErrorMessage}
        locationDeIsValid={locationDeIsValid}
        descriptionEl={concertDescriptionEl}
        setDescriptionEl={setConcertDescriptionEl}
        descriptionElError={concertDescriptionElError}
        setDescriptionElError={setConcertDescriptionElError}
        descriptionElErrorMessage={descriptionElErrorMessage}
        descriptionElIsValid={descriptionElIsValid}
        descriptionEn={concertDescriptionEn}
        setDescriptionEn={setConcertDescriptionEn}
        descriptionEnError={concertDescriptionEnError}
        setDescriptionEnError={setConcertDescriptionEnError}
        descriptionEnErrorMessage={descriptionEnErrorMessage}
        descriptionEnIsValid={descriptionEnIsValid}
        descriptionDe={concertDescriptionDe}
        setDescriptionDe={setConcertDescriptionDe}
        descriptionDeError={concertDescriptionDeError}
        setDescriptionDeError={setConcertDescriptionDeError}
        descriptionDeErrorMessage={descriptionDeErrorMessage}
        descriptionDeIsValid={descriptionDeIsValid}
        link={concertLink}
        setLink={setConcertLink}
        linkError={concertLinkError}
        setLinkError={setConcertLinkError}
        linkErrorMessage={linkErrorMessage}
        linkIsValid={linkIsValid}
        media={concertMedia}
        setMedia={setConcertMedia}
        mediaError={concertMediaError}
        setMediaError={setConcertMediaError}
        mediaErrorMessage={mediaErrorMessage}
        mediaIsValid={mediaIsValid}
        isLoading={isLoading}
      />
      <div className={styles["form_btn_container"]}>
        <button
          type="submit"
          className={styles["form_submit_btn"]}
          disabled={isLoading || !formIsValid}
        >
          Δημιουργία Συναυλίας
        </button>
      </div>
      {error && error.trim() !== "" && (
        <p className={styles["form_error"]}>{error}</p>
      )}
    </form>
  );
};

export default CreateConcertForm;
