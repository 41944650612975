import { FC, useState } from "react";

import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { IUomConcertBrief } from "../../../interfaces/IUomConcert";
import { ModalDialog, ModalDialogActionStyle } from "../..";

import styles from "./UomConcertCard.module.css";

interface UomConcertCardProps {
  concert: IUomConcertBrief;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}

const UomConcertCard: FC<UomConcertCardProps> = ({
  concert,
  onDelete,
  onEdit,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(concert.id);
    onCancelAction();
  };

  const concertDate =
    concert.dateFrom === concert.dateTo
      ? concert.dateFrom
      : `${concert.dateFrom} - ${concert.dateTo}`;

  return (
    <div className={styles["concert-container"]}>
      <div className={styles["concert-image"]}>
        {concert.image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/images/uom-concerts/${concert.image}`}
            alt="Εικόνα συναυλίας"
          />
        ) : (
          <p>Δεν υπάρχει εικόνα</p>
        )}
      </div>
      <div className={styles["concert-info"]}>
        <p>{concertDate}</p>
        <p>{concert.description}</p>
      </div>
      <div className={styles["concert-actions"]}>
        <AiFillEdit onClick={() => onEdit(concert.id)} />
        <AiFillDelete onClick={onShowDeleteDialog} />
      </div>
      <div className={styles["concert-actions-small"]}>
        <button
          className={styles["btn_primary"]}
          onClick={() => onEdit(concert.id)}
        >
          Επεξεργασία
        </button>
        <button className={styles["btn_error"]} onClick={onShowDeleteDialog}>
          Διαγραφή
        </button>
      </div>
      {showDeleteDialog && (
        <ModalDialog
          title="Διαγραφή συναυλίας"
          content={`Είστε σίγουροι ότι θέλετε να διαγράψετε τη συναυλία στις ${concert.dateFrom};`}
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: onConfirmAction,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </div>
  );
};

export default UomConcertCard;
