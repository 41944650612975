import { FC, ChangeEvent, useState } from "react";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import MDEditor, {
  commands,
  ICommand,
  TextState,
  TextAreaTextApi,
} from "@uiw/react-md-editor";

import { Language } from "../../../interfaces/Language";

import styles from "./ConcertInfoForm.module.css";

const styling: ICommand = {
  name: "styling",
  keyCommand: "styling",
  buttonProps: { "aria-label": "Insert styles" },
  icon: (
    <svg width="12" height="12" viewBox="0 0 520 520">
      <path
        fill="black"
        d="M256.282 339.488zM64 32l34.946 403.219L255.767 480l157.259-44.85L448 32H64zm290.676 334.898l-98.607 28.125-98.458-28.248L150.864 289h48.253l3.433 39.562 53.586 15.163.132.273h.034l53.467-14.852L315.381 265H203l-4-50h120.646l4.396-51H140l-4-49h240.58l-21.904 251.898z"
      />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    let modifyText = `${state.selectedText}\n<!--rehype:style=text-align: center; bbackground-color: black; color: white; -->`;
    if (!state.selectedText) {
      modifyText = `\n<!--rehype:style=text-align: center; background-color: black; color: white; -->`;
    }
    api.replaceSelection(modifyText);
  },
};

interface ConcertInfoFormProps {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  dateError: string;
  setDateError: React.Dispatch<React.SetStateAction<string>>;
  dateErrorMessage: string | null;
  dateIsValid: boolean;
  time: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  timeError: string;
  setTimeError: React.Dispatch<React.SetStateAction<string>>;
  timeErrorMessage: string | null;
  timeIsValid: boolean;
  locationEl: string;
  setLocationEl: React.Dispatch<React.SetStateAction<string>>;
  locationElError: string;
  setLocationElError: React.Dispatch<React.SetStateAction<string>>;
  locationElErrorMessage: string | null;
  locationElIsValid: boolean;
  locationEn: string;
  setLocationEn: React.Dispatch<React.SetStateAction<string>>;
  locationEnError: string;
  setLocationEnError: React.Dispatch<React.SetStateAction<string>>;
  locationEnErrorMessage: string | null;
  locationEnIsValid: boolean;
  locationDe: string;
  setLocationDe: React.Dispatch<React.SetStateAction<string>>;
  locationDeError: string;
  setLocationDeError: React.Dispatch<React.SetStateAction<string>>;
  locationDeErrorMessage: string | null;
  locationDeIsValid: boolean;
  descriptionEl: string | undefined;
  setDescriptionEl: React.Dispatch<React.SetStateAction<string | undefined>>;
  descriptionElError: string;
  setDescriptionElError: React.Dispatch<React.SetStateAction<string>>;
  descriptionElErrorMessage: string | null;
  descriptionElIsValid: boolean;
  descriptionEn: string | undefined;
  setDescriptionEn: React.Dispatch<React.SetStateAction<string | undefined>>;
  descriptionEnError: string;
  setDescriptionEnError: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnErrorMessage: string | null;
  descriptionEnIsValid: boolean;
  descriptionDe: string | undefined;
  setDescriptionDe: React.Dispatch<React.SetStateAction<string | undefined>>;
  descriptionDeError: string;
  setDescriptionDeError: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeErrorMessage: string | null;
  descriptionDeIsValid: boolean;
  link: string | null;
  setLink: React.Dispatch<React.SetStateAction<string | null>>;
  linkError: string;
  setLinkError: React.Dispatch<React.SetStateAction<string>>;
  linkErrorMessage: string | null;
  linkIsValid: boolean;
  media: string[] | null;
  setMedia: React.Dispatch<React.SetStateAction<string[] | null>>;
  mediaError: string;
  setMediaError: React.Dispatch<React.SetStateAction<string>>;
  mediaErrorMessage: string | null;
  mediaIsValid: boolean;
  isLoading: boolean;
}

const ConcertInfoForm: FC<ConcertInfoFormProps> = ({
  date,
  setDate,
  dateError,
  setDateError,
  dateErrorMessage,
  dateIsValid,
  time,
  setTime,
  timeError,
  setTimeError,
  timeErrorMessage,
  timeIsValid,
  locationEl,
  setLocationEl,
  locationElError,
  setLocationElError,
  locationElErrorMessage,
  locationElIsValid,
  locationEn,
  setLocationEn,
  locationEnError,
  setLocationEnError,
  locationEnErrorMessage,
  locationEnIsValid,
  locationDe,
  setLocationDe,
  locationDeError,
  setLocationDeError,
  locationDeErrorMessage,
  locationDeIsValid,
  descriptionEl,
  setDescriptionEl,
  descriptionElError,
  setDescriptionElError,
  descriptionElErrorMessage,
  descriptionElIsValid,
  descriptionEn,
  setDescriptionEn,
  descriptionEnError,
  setDescriptionEnError,
  descriptionEnErrorMessage,
  descriptionEnIsValid,
  descriptionDe,
  setDescriptionDe,
  descriptionDeError,
  setDescriptionDeError,
  descriptionDeErrorMessage,
  descriptionDeIsValid,
  link,
  setLink,
  linkError,
  setLinkError,
  linkErrorMessage,
  linkIsValid,
  media,
  setMedia,
  mediaError,
  setMediaError,
  mediaErrorMessage,
  mediaIsValid,
  isLoading,
}) => {
  const [language, setLanguage] = useState<Language>(Language.GREEK);

  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (dateError !== "") setDateError("");
    setDate(event.target.value);
  };

  const dateBlurHandler = () => {
    if (!dateIsValid && dateErrorMessage) {
      setDateError(dateErrorMessage);
    } else {
      setDateError("");
    }
  };

  const timeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (timeError !== "") setTimeError("");
    setTime(event.target.value);
  };

  const timeBlurHandler = () => {
    if (!timeIsValid && timeErrorMessage) {
      setTimeError(timeErrorMessage);
    } else {
      setTimeError("");
    }
  };

  const locationElChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (locationElError !== "") setLocationElError("");
    setLocationEl(event.target.value);
  };

  const locationElBlurHandler = () => {
    if (!locationElIsValid && locationElErrorMessage) {
      setLocationElError(locationElErrorMessage);
    } else {
      setLocationElError("");
    }
  };

  const locationEnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (locationEnError !== "") setLocationEnError("");
    setLocationEn(event.target.value);
  };

  const locationEnBlurHandler = () => {
    if (!locationEnIsValid && locationEnErrorMessage) {
      setLocationEnError(locationEnErrorMessage);
    } else {
      setLocationEnError("");
    }
  };

  const locationDeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (locationDeError !== "") setLocationDeError("");
    setLocationDe(event.target.value);
  };

  const locationDeBlurHandler = () => {
    if (!locationDeIsValid && locationDeErrorMessage) {
      setLocationDeError(locationDeErrorMessage);
    } else {
      setLocationDeError("");
    }
  };

  const descriptionElChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl(event.target.value);
  };

  const descriptionElBlurHandler = () => {
    if (!descriptionElIsValid && descriptionElErrorMessage) {
      setDescriptionEl(descriptionElErrorMessage);
    } else {
      setDescriptionEl("");
    }
  };

  const descriptionEnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn(event.target.value);
  };

  const descriptionEnBlurHandler = () => {
    if (!descriptionEnIsValid && descriptionEnErrorMessage) {
      setDescriptionEn(descriptionEnErrorMessage);
    } else {
      setDescriptionEn("");
    }
  };

  const descriptionDeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe(event.target.value);
  };

  const descriptionDeBlurHandler = () => {
    if (!descriptionDeIsValid && descriptionDeErrorMessage) {
      setDescriptionDe(descriptionDeErrorMessage);
    } else {
      setDescriptionDe("");
    }
  };

  const linkChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (linkError !== "") setLinkError("");
    setLink(event.target.value);
  };

  const linkBlurHandler = () => {
    if (!linkIsValid && linkErrorMessage) {
      setLinkError(linkErrorMessage);
    } else {
      setLinkError("");
    }
  };

  const mediaAddHandler = () => {
    setMedia((current) => {
      if (!current) return [""];
      const newMedia = current.slice();
      newMedia.push("");
      return newMedia;
    });
  };

  const mediaRemoveHandler = (idx: number) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1);
      return newMedia;
    });
  };

  const mediaChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1, event.target.value);
      return newMedia;
    });
  };

  const mediaBlurHandler = () => {
    if (!mediaIsValid && mediaErrorMessage) {
      setMediaError(mediaErrorMessage);
    } else {
      setMediaError("");
    }
  };

  const languageChangeHandler = (lang: Language) => setLanguage(lang);

  return (
    <>
      {/* Date input */}
      <div className={styles["form_field"]}>
        <label htmlFor="date-input">Ημερομηνία Συναυλίας</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="date-input"
            type="date"
            disabled={isLoading}
            value={date}
            onChange={dateChangeHandler}
            onBlur={dateBlurHandler}
            required
            aria-required
          />
        </div>
        {dateError && dateError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{dateError}</p>
        )}
      </div>

      {/* Time input */}
      <div className={styles["form_field"]}>
        <label htmlFor="time-input">Ώρα Συναυλίας</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="time-input"
            type="time"
            disabled={isLoading}
            value={time}
            onChange={timeChangeHandler}
            onBlur={timeBlurHandler}
          />
        </div>
        {timeError && timeError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{timeError}</p>
        )}
      </div>

      {/* Select Language */}
      <div className={styles["language-selection"]}>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GREEK)}
          className={`${
            language === Language.GREEK
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${
            (!locationEnIsValid || !descriptionElIsValid) && styles["has-error"]
          }`}
        >
          Ελληνικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.ENGLISH)}
          className={`${
            language === Language.ENGLISH
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${
            (!locationEnIsValid || !descriptionEnIsValid) && styles["has-error"]
          }`}
        >
          Αγγλικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GERMAN)}
          className={`${
            language === Language.GERMAN
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
              ${
                (!locationDeIsValid || !descriptionDeIsValid) &&
                styles["has-error"]
              }`}
        >
          Γερμανικά
        </button>
      </div>

      {/* Location Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="location-el-input">Τοποθεσία Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="location-el-input"
              type="text"
              placeholder="π.χ. Μέγαρο Μουσικής"
              disabled={isLoading}
              value={locationEl}
              onChange={locationElChangeHandler}
              onBlur={locationElBlurHandler}
              required
              aria-required
            />
          </div>
          {locationElError && locationElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{locationElError}</p>
          )}
        </div>
      )}

      {/* Location English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="location-en-input">Τοποθεσία Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="location-en-input"
              type="text"
              placeholder="π.χ. Μέγαρο Μουσικής"
              disabled={isLoading}
              value={locationEn}
              onChange={locationEnChangeHandler}
              onBlur={locationEnBlurHandler}
              required
              aria-required
            />
          </div>
          {locationEnError && locationEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{locationEnError}</p>
          )}
        </div>
      )}

      {/* Location German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="location-de-input">Τοποθεσία Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="location-de-input"
              type="text"
              placeholder="π.χ. Μέγαρο Μουσικής"
              disabled={isLoading}
              value={locationDe}
              onChange={locationDeChangeHandler}
              onBlur={locationDeBlurHandler}
              required
              aria-required
            />
          </div>
          {locationDeError && locationDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{locationDeError}</p>
          )}
        </div>
      )}

      {/* Description Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]} data-color-mode="light">
          <label htmlFor="description-el-input">Περιγραφή Ελληνικά</label>
          <MDEditor
            id="description-el-input"
            height={500}
            value={descriptionEl}
            onChange={setDescriptionEl}
            draggable={false}
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.group(
                [
                  commands.title1,
                  commands.title2,
                  commands.title3,
                  commands.title4,
                  commands.title5,
                  commands.title6,
                ],
                {
                  name: "title",
                  groupName: "title",
                  buttonProps: { "aria-label": "Insert title" },
                }
              ),
              // styling,
              commands.divider,
              commands.link,
              commands.quote,
              commands.divider,
              commands.unorderedListCommand,
              commands.orderedListCommand,
              commands.checkedListCommand,
            ]}
          />
          {descriptionElError && descriptionElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionElError}</p>
          )}
        </div>
      )}

      {/* Description English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]} data-color-mode="light">
          <label htmlFor="description-en-input">Περιγραφή Αγγλικά</label>
          <MDEditor
            id="description-en-input"
            height={500}
            value={descriptionEn}
            onChange={setDescriptionEn}
            draggable={false}
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.group(
                [
                  commands.title1,
                  commands.title2,
                  commands.title3,
                  commands.title4,
                  commands.title5,
                  commands.title6,
                ],
                {
                  name: "title",
                  groupName: "title",
                  buttonProps: { "aria-label": "Insert title" },
                }
              ),
              // styling,
              commands.divider,
              commands.link,
              commands.quote,
              commands.divider,
              commands.unorderedListCommand,
              commands.orderedListCommand,
              commands.checkedListCommand,
            ]}
          />
          {descriptionEnError && descriptionEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionEnError}</p>
          )}
        </div>
      )}

      {/* Description German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]} data-color-mode="light">
          <label htmlFor="description-de-input">Περιγραφή Γερμανικά</label>
          <MDEditor
            id="description-de-input"
            height={500}
            value={descriptionDe}
            onChange={setDescriptionDe}
            draggable={false}
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.group(
                [
                  commands.title1,
                  commands.title2,
                  commands.title3,
                  commands.title4,
                  commands.title5,
                  commands.title6,
                ],
                {
                  name: "title",
                  groupName: "title",
                  buttonProps: { "aria-label": "Insert title" },
                }
              ),
              // styling,
              commands.divider,
              commands.link,
              commands.quote,
              commands.divider,
              commands.unorderedListCommand,
              commands.orderedListCommand,
              commands.checkedListCommand,
            ]}
          />
          {descriptionDeError && descriptionDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionDeError}</p>
          )}
        </div>
      )}

      {/* Link input */}
      <div className={styles["form_field"]}>
        <label htmlFor="link-input">Link Συναυλίας (προαιρετικό)</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="link-input"
            type="text"
            placeholder="π.χ. https://www.uwematschkepianist.com"
            disabled={isLoading}
            value={link || ""}
            onChange={linkChangeHandler}
            onBlur={linkBlurHandler}
          />
        </div>
        {linkError && linkError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{linkError}</p>
        )}
      </div>

      {/* Media input */}
      <div className={styles["form_field"]}>
        <label htmlFor="media-input">Media Συναυλίας (προαιρετικό)</label>
        {media &&
          media.length > 0 &&
          media.map((m, idx) => (
            <div
              key={`media-${idx}`}
              className={
                isLoading
                  ? styles["form_field_input-disabled"]
                  : styles["form_field_input"]
              }
            >
              <input
                id={`media-input-${idx}`}
                type="text"
                placeholder="π.χ. https://www.youtube.com/..."
                disabled={isLoading}
                value={m}
                onChange={(e) => mediaChangeHandler(e, idx)}
                onBlur={mediaBlurHandler}
                required
                aria-required
              />
              <span className={styles["form_field_input-icon_end"]}>
                <AiFillDelete onClick={() => mediaRemoveHandler(idx)} />
              </span>
            </div>
          ))}
        <button
          type="button"
          onClick={mediaAddHandler}
          disabled={isLoading}
          className={
            isLoading
              ? styles["media-add-button-disabled"]
              : styles["media-add-button"]
          }
        >
          Προσθέστε links για περισσότερα Media
          <span className={styles["form_field_input-icon_end"]}>
            <AiFillPlusCircle />
          </span>
        </button>
        {mediaError && mediaError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{mediaError}</p>
        )}
      </div>
    </>
  );
};

export default ConcertInfoForm;
