import {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  FormEvent,
} from "react";

import { ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa";

import { validateImageFile } from "../../../utils/input";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import styles from "./GalleryImageForm.module.css";

interface GalleryImageFormProps {
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const maxSize = 8; // 8 MB

const GalleryImageForm: FC<GalleryImageFormProps> = ({ onRefresh }) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedFileError, setSelectedFileError] = useState<
    string | undefined
  >();
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );
  const allowedImages: boolean = !selectedFile;

  const imageIsValid = selectedFile && validateImageFile(selectedFile);

  // Use reference to file input to open the dialog from button click
  const openFileSelection = () => {
    fileInputRef.current?.click();
  };

  // Set the image preview whenever the selected file changes
  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setImagePreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, setImagePreview]);

  /**
   * On file input change, check if the selected file was changed and if it's valid (type & size) update the selectedFile state
   * @param {ChangeEvent} event Input file selection was changed event
   */
  const onSelectFile = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    // if no file was selected, return
    if (!target || !target.files || target.files.length === 0) {
      return;
    }

    const newSelectedFile = target.files[0];
    // if selected file was not an image, return
    if (!validateImageFile(newSelectedFile)) {
      setSelectedFile(undefined);
      setSelectedFileError(
        `Μη αποδεκτός τύπος αρχείου (${newSelectedFile.type})`
      );
      return;
    }

    // maxSize MB ~= maxSize * 2^20 bytes
    if (newSelectedFile.size > maxSize * 1024 * 1024) {
      setSelectedFileError("Το μέγεθος του αρχείου ξεπερνάει το μέγιστο");
      return;
    }
    setSelectedFileError(undefined);
    setSelectedFile(target.files[0]);
  };

  /**
   * Delete a selected image from selected images array
   */
  const deleteSelectedFile = () => {
    if (!selectedFile) {
      return;
    }

    setSelectedFile(undefined);
  };

  const uploadImage = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!imageIsValid || !selectedFile) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      setSelectedFile(undefined);
      setSelectedFileError(undefined);

      onRefresh();
    };

    const formData = new FormData();
    formData.append("contestImage", selectedFile);

    // send POST request to API's route /admin/uom/contest/upload-image
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/contest/upload-image`;
    sendRequest(
      {
        url,
        method: "POST",
        token: auth.token,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      },
      transformResponse
    );
  };

  return (
    <form className={styles["form_field"]} onSubmit={uploadImage}>
      {allowedImages ? (
        <label htmlFor="image-input">Επιλέξτε Εικόνα</label>
      ) : (
        <h2>Σβήστε την υπάρχουσα εικόνα για να προσθέσετε άλλη</h2>
      )}
      <p className={styles["form_text"]}>
        Προτιμήστε εικόνα με καλή ανάλυση και το κύριο περιεχόμενο στο κέντρο
        της εικόνας.
      </p>
      <small className={styles["form_field_status"]}>
        Αποδεκτοί τύποι αρχείων: .jpg, .jpeg, .png <br /> Μέγιστο επιτρεπόμενο
        μέγεθος: {maxSize} MB
      </small>
      {selectedFileError && (
        <p className={styles["form_field_error"]}>{selectedFileError}</p>
      )}
      <div className={styles["form_images"]}>
        {allowedImages && (
          <input
            ref={fileInputRef}
            id="image-input"
            className={styles["form_hidden"]}
            type="file"
            multiple={false}
            onChange={onSelectFile}
            accept=".jpg, .jpeg, .png"
            required
            aria-required
          />
        )}
        {imagePreview && (
          <div
            className={styles["form_field_image"]}
            onClick={() => deleteSelectedFile()}
          >
            <img src={imagePreview} alt="Επιλεγμένη εικόνα" />
            <ImCross className={styles["form_field_image-icon"]} />
          </div>
        )}
        {allowedImages && (
          <div
            className={styles["form_field_image"]}
            onClick={openFileSelection}
          >
            <FaPlus />
            <p>Επιλέξτε εικόνα</p>
          </div>
        )}
        <div className={styles["form_btn_container"]}>
          <button
            type="submit"
            className={styles["form_submit_btn"]}
            disabled={isLoading || !imageIsValid}
          >
            Αποθήκευση Εικόνας
          </button>
        </div>
        {error && error.trim() !== "" && (
          <p className={styles["form_error"]}>{error}</p>
        )}
      </div>
    </form>
  );
};

export default GalleryImageForm;
