import { FC, useState } from "react";

import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { IUomContestBrief } from "../../../interfaces/IUomContest";
import { ModalDialog, ModalDialogActionStyle } from "../..";

import styles from "./UomContestCard.module.css";

interface UomContestCardProps {
  contest: IUomContestBrief;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}

const UomContestCard: FC<UomContestCardProps> = ({
  contest,
  onDelete,
  onEdit,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(contest.id);
    onCancelAction();
  };

  return (
    <div className={styles["contest-container"]}>
      <div className={styles["contest-image"]}>
        {contest.image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/images/uom-contests/${contest.image}`}
            alt="Εικόνα διαγωνισμού"
          />
        ) : (
          <p>Δεν υπάρχει εικόνα</p>
        )}
      </div>
      <div className={styles["contest-info"]}>
        <p>{contest.description}</p>
      </div>
      <div className={styles["contest-actions"]}>
        <AiFillEdit onClick={() => onEdit(contest.id)} />
        <AiFillDelete onClick={onShowDeleteDialog} />
      </div>
      {showDeleteDialog && (
        <ModalDialog
          title="Διαγραφή διαγωνισμού φοιτητή"
          content={`Είστε σίγουροι ότι θέλετε να διαγράψετε το διαγωνισμό;`}
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: onConfirmAction,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </div>
  );
};

export default UomContestCard;
