import { FC, useState, useRef } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";

import { IVideoBrief } from "../../../interfaces/IVideo";
import { ModalDialog, ModalDialogActionStyle } from "../..";
import { ItemTypes } from "../ItemTypes";

import styles from "./VideoCard.module.css";

interface VideoCardProps {
  index: number;
  order: number;
  video: IVideoBrief;
  moveVideo: (dragIndex: number, hoverIndex: number) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  isLoading: boolean;
}

interface DragItem {
  type: string;
  id: string;
  index: number;
}

const VideoCard: FC<VideoCardProps> = ({
  index,
  order,
  video,
  moveVideo,
  onDelete,
  onEdit,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.CARD,
      id: video.id,
      index,
    } as DragItem,
    type: ItemTypes.CARD,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: true,
  });

  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height/width
      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return;
      }

      // Time to actually perform the action
      moveVideo(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(video.id);
    onCancelAction();
  };

  drag(drop(ref));
  return (
    <div ref={ref}>
      <div
        className={styles["video-container"]}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <div className={styles["video-image"]}>
          {video.image ? (
            <img
              src={`${process.env.REACT_APP_BASE_URL}/images/videos/${video.image}`}
              alt="Thumbnail βίντεο"
            />
          ) : (
            <p>Δεν υπάρχει εικόνα</p>
          )}
        </div>
        <div className={styles["video-info"]}>
          <a href={video.link}>{video.description}</a>
        </div>
        <div className={styles["video-actions"]}>
          <AiFillEdit onClick={() => onEdit(video.id)} />
          <AiFillDelete onClick={onShowDeleteDialog} />
        </div>
        <div className={styles["video-actions-small"]}>
          <button
            className={styles["btn_primary"]}
            onClick={() => onEdit(video.id)}
          >
            Επεξεργασία
          </button>
          <button className={styles["btn_error"]} onClick={onShowDeleteDialog}>
            Διαγραφή
          </button>
        </div>
        {showDeleteDialog && (
          <ModalDialog
            title="Διαγραφή video"
            content={`Είστε σίγουροι ότι θέλετε να διαγράψετε το video "${video.description}";`}
            actions={[
              {
                text: "Διαγραφή",
                style: ModalDialogActionStyle.Error,
                onClick: onConfirmAction,
              },
              {
                text: "Ακύρωση",
                style: ModalDialogActionStyle.Secondary,
                onClick: onCancelAction,
              },
            ]}
            onClose={onCancelAction}
          />
        )}
      </div>
    </div>
  );
};

export default VideoCard;
