import { FC, useContext, useState, useEffect } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import classNames from "classnames";

import { ConcertBrief } from "../../../interfaces/Concert";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { ConcertCard } from "..";

import styles from "./ConcertsList.module.css";

interface ConcertsGridProps {
  concerts: ConcertBrief[];
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const ConcertsGrid: FC<ConcertsGridProps> = ({
  concerts: initialConcerts,
  onRefresh,
}) => {
  const navigate: NavigateFunction = useNavigate();

  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  const [concerts, setConcerts] = useState<ConcertBrief[]>([]);

  // whenever initial list of concerts is changed (e.g. refresh or response from API), update the list of concerts
  useEffect(() => {
    setConcerts(initialConcerts);
  }, [initialConcerts]);

  // handle request to delete a concert
  const deleteHandler = (date: string) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/concert/delete/${date}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  // navigate to edit concert page
  const editHandler = (date: string) => {
    navigate(`/concerts/edit/${date}`);
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {concerts && concerts.length > 0 && (
        <div className={classNames(styles["concerts-grid"])}>
          {concerts.map((concert, index) => (
            <div key={`t-${concert.date}`} style={{ margin: "8px 0" }}>
              <ConcertCard
                concert={concert}
                onDelete={deleteHandler}
                onEdit={editHandler}
                isLoading={isLoading}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ConcertsGrid;
