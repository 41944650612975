import {
  FC,
  useState,
  useContext,
  FormEvent,
  useEffect,
  useCallback,
} from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import {
  validateText,
  validateDigitText,
  validateUrl,
} from "../../../utils/input";

import { UomContestInfoForm, UomContestImageForm } from "..";

import styles from "./AddUomContestForm.module.css";

interface ApiResponse {
  message: string;
}

interface ImagesApiResponse {
  images: string[];
}

const AddUomContestForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;

  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  const [contestYear, setContestYear] = useState<string>(
    `${new Date().getFullYear()}`
  );
  const [contestDescriptionEl, setContestDescriptionEl] = useState<string>("");
  const [contestDescriptionEn, setContestDescriptionEn] = useState<string>("");
  const [contestDescriptionDe, setContestDescriptionDe] = useState<string>("");
  const [contestLink, setContestLink] = useState<string | null>("");
  const [contestImage, setContestImage] = useState<string | null>("");

  const [contestYearError, setContestYearError] = useState<string>("");
  const [contestDescriptionElError, setContestDescriptionElError] =
    useState<string>("");
  const [contestDescriptionEnError, setContestDescriptionEnError] =
    useState<string>("");
  const [contestDescriptionDeError, setContestDescriptionDeError] =
    useState<string>("");
  const [contestLinkError, setContestLinkError] = useState<string>("");

  const yearErrorMessage = validateDigitText(contestYear, 4);
  const yearIsValid = yearErrorMessage == null;
  const descriptionElErrorMessage = validateText(contestDescriptionEl, 5000);
  const descriptionElIsValid = descriptionElErrorMessage == null;
  const descriptionEnErrorMessage = validateText(contestDescriptionEn, 5000);
  const descriptionEnIsValid = descriptionEnErrorMessage == null;
  const descriptionDeErrorMessage = validateText(contestDescriptionDe, 5000);
  const descriptionDeIsValid = descriptionDeErrorMessage == null;
  const linkErrorMessage =
    contestLink && contestLink.trim().length > 0
      ? validateUrl(contestLink)
      : null;
  const linkIsValid = linkErrorMessage == null;

  const formIsValid =
    yearIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid &&
    linkIsValid;

  const createContest = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate("/uom/contests", { replace: true });
    };

    // send POST request to API's route /admin/uom/contest/create
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/contest/create`;

    const image = contestImage?.split(".")[0];
    sendRequest(
      {
        url,
        method: "POST",
        token: auth.token,
        data: {
          year: contestYear.trim(),
          descriptionEl: contestDescriptionEl?.trim() || "",
          descriptionEn: contestDescriptionEn?.trim() || "",
          descriptionDe: contestDescriptionDe?.trim() || "",
          link: contestLink?.trim(),
          image,
        },
      },
      transformResponse
    );
  };

  const [images, setImages] = useState<string[]>([]);

  // Get all images from API and set state
  const getImages = useCallback(() => {
    // transform API response
    const transformResponse = (response: ImagesApiResponse) =>
      setImages(response.images);

    const url = `${process.env.REACT_APP_API_URL}/admin/uom/contest/images`;

    sendRequest({ url, token: auth.token, method: "GET" }, transformResponse);
  }, [sendRequest]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <form className={styles["form_container"]} onSubmit={createContest}>
      <UomContestInfoForm
        year={contestYear}
        setYear={setContestYear}
        yearError={contestYearError}
        setYearError={setContestYearError}
        yearErrorMessage={yearErrorMessage}
        yearIsValid={yearIsValid}
        descriptionEl={contestDescriptionEl}
        setDescriptionEl={setContestDescriptionEl}
        descriptionElError={contestDescriptionElError}
        setDescriptionElError={setContestDescriptionElError}
        descriptionElErrorMessage={descriptionElErrorMessage}
        descriptionElIsValid={descriptionElIsValid}
        descriptionEn={contestDescriptionEn}
        setDescriptionEn={setContestDescriptionEn}
        descriptionEnError={contestDescriptionEnError}
        setDescriptionEnError={setContestDescriptionEnError}
        descriptionEnErrorMessage={descriptionEnErrorMessage}
        descriptionEnIsValid={descriptionEnIsValid}
        descriptionDe={contestDescriptionDe}
        setDescriptionDe={setContestDescriptionDe}
        descriptionDeError={contestDescriptionDeError}
        setDescriptionDeError={setContestDescriptionDeError}
        descriptionDeErrorMessage={descriptionDeErrorMessage}
        descriptionDeIsValid={descriptionDeIsValid}
        link={contestLink}
        setLink={setContestLink}
        linkError={contestLinkError}
        setLinkError={setContestLinkError}
        linkErrorMessage={linkErrorMessage}
        linkIsValid={linkIsValid}
        isLoading={isLoading}
      />
      <UomContestImageForm
        images={images}
        image={contestImage}
        onSetImage={setContestImage}
        existingImage={!!contestImage}
        isLoading={isLoading}
      />
      <div className={styles["form_btn_container"]}>
        <button
          type="submit"
          className={styles["form_submit_btn"]}
          disabled={isLoading || !formIsValid}
        >
          Δημιουργία
        </button>
      </div>
      {error && error.trim() !== "" && (
        <p className={styles["form_error"]}>{error}</p>
      )}
    </form>
  );
};

export default AddUomContestForm;
