import {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  FormEvent,
} from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { ImCross } from "react-icons/im";
import { FaPlus } from "react-icons/fa";

import { validatePdfFile } from "../../../utils/input";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import styles from "./UomConcertPdfForm.module.css";

interface UomConcertPdfFormProps {
  id: string | undefined;
  existingFile: boolean;
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const UomConcertPdfForm: FC<UomConcertPdfFormProps> = ({
  id,
  existingFile,
  onRefresh,
}) => {
  const { auth } = useContext(AuthContext) as AuthContextType;
  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedFileError, setSelectedFileError] = useState<
    string | undefined
  >();
  const [filePreview, setFilePreview] = useState<string | undefined>(undefined);
  const allowedFiles: boolean = !selectedFile && !existingFile;

  const fileIsValid = selectedFile && validatePdfFile(selectedFile);

  // Use reference to file input to open the dialog from button click
  const openFileSelection = () => {
    fileInputRef.current?.click();
  };

  // Set the PDF preview whenever the selected file changes
  useEffect(() => {
    if (!selectedFile) {
      setFilePreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setFilePreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, setFilePreview]);

  /**
   * On file input change, check if the selected file was changed and if it's valid (type & size) update the selectedFile state
   * @param {ChangeEvent} event Input file selection was changed event
   */
  const onSelectFile = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    // if no file was selected, return
    if (!target || !target.files || target.files.length === 0) {
      return;
    }

    const newSelectedFile = target.files[0];
    // if selected file was not a PDF file, return
    if (!validatePdfFile(newSelectedFile)) {
      setSelectedFile(undefined);
      setSelectedFileError(
        `Μη αποδεκτός τύπος αρχείου (${newSelectedFile.type})`
      );
      return;
    }

    // 15 MB ~= 15 * 1024 * 1024 bytes (15*2^20)
    if (newSelectedFile.size > 15 * 1024 * 1024) {
      setSelectedFileError(
        `Το μέγεθος του αρχείου (~ ${
          newSelectedFile.size / (1024 * 1024)
        } ΜΒ) ξεπερνάει το μέγιστο`
      );
      return;
    }
    setSelectedFileError(undefined);
    setSelectedFile(target.files[0]);
  };

  /**
   * Delete a selected file from selected files array
   */
  const deleteSelectedFile = () => {
    if (!selectedFile) {
      return;
    }

    setSelectedFile(undefined);
  };

  const uploadFile = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!fileIsValid || !selectedFile) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      setSelectedFile(undefined);
      setSelectedFileError(undefined);
      onRefresh();
    };

    const formData = new FormData();
    formData.append("concertFile", selectedFile);

    // send POST request to API's route /admin/uom/concert/upload-file/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/concert/upload-file/${id}`;
    sendRequest(
      {
        url,
        method: "POST",
        token: auth.token,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      },
      transformResponse
    );
  };

  return (
    <form className={styles["form_field"]} onSubmit={uploadFile}>
      {allowedFiles ? (
        <label htmlFor="file-input">
          Επιλέξτε Αρχείο PDF
          {existingFile && (
            <>
              <br />
              Σβήστε το υπάρχον ή το επιλεγμένο αρχείο για να προσθέσετε άλλο
            </>
          )}
        </label>
      ) : (
        <h2>Σβήστε το υπάρχον αρχείο για να προσθέσετε άλλο</h2>
      )}
      {!existingFile && (
        <>
          <p className={styles["form_text"]}>
            Επιλέξτε αρχείο μικρού μεγέθους για γρηγορότερη φόρτωση.
          </p>
          <small className={styles["form_field_status"]}>
            Αποδεκτοί τύποι αρχείων: .pdf <br /> Μέγιστο επιτρεπόμενο μέγεθος:
            15 MB
          </small>
        </>
      )}
      {selectedFileError && (
        <p className={styles["form_field_error"]}>{selectedFileError}</p>
      )}
      <div className={styles["form_files"]}>
        {allowedFiles && (
          <input
            ref={fileInputRef}
            id="file-input"
            className={styles["form_hidden"]}
            type="file"
            multiple={false}
            onChange={onSelectFile}
            accept="application/pdf, .pdf"
            required
            aria-required
          />
        )}
        {filePreview && (
          <div
            className={styles["form_field_file"]}
            onClick={() => deleteSelectedFile()}
          >
            <Document file={filePreview}>
              <Page pageNumber={1} />
            </Document>
            <ImCross className={styles["form_field_file-icon"]} />
          </div>
        )}
        {allowedFiles && (
          <div
            className={styles["form_field_file"]}
            onClick={openFileSelection}
          >
            <FaPlus />
            <p>Επιλέξτε αρχείο</p>
          </div>
        )}
        {!existingFile && (
          <div className={styles["form_btn_container"]}>
            <button
              type="submit"
              className={styles["form_submit_btn"]}
              disabled={isLoading || !fileIsValid}
            >
              Ανέβασμα PDF
            </button>
          </div>
        )}
        {error && error.trim() !== "" && (
          <p className={styles["form_error"]}>{error}</p>
        )}
      </div>
    </form>
  );
};

export default UomConcertPdfForm;
