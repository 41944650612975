import { FC } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import styles from "./UomConcert.module.css";

enum ActiveLink {
  UomConcertAll = 0,
  AddUomConcert = 2,
  EditUomConcert = 3,
}

const UomConcert: FC = () => {
  let selected: ActiveLink | null;
  const location = useLocation();
  switch (location.pathname) {
    case "/uom/concerts/add":
      selected = ActiveLink.AddUomConcert;
      break;
    case "/uom/concerts":
      selected = ActiveLink.UomConcertAll;
      break;
    default:
      selected = ActiveLink.EditUomConcert;
      break;
  }

  return (
    <>
      <nav className={styles["concerts-navigation"]}>
        <Link
          to=""
          className={
            selected === ActiveLink.UomConcertAll
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          }
        >
          Προβολή
        </Link>
        <Link
          to="add"
          className={
            selected === ActiveLink.AddUomConcert
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          }
        >
          Δημιουργία
        </Link>
        <Link
          to="edit"
          aria-disabled
          onClick={(e) => e.preventDefault()}
          className={`${
            selected === ActiveLink.EditUomConcert
              ? styles["concerts-navigation-link_active"]
              : styles["concerts-navigation-link_inactive"]
          } ${styles["concerts-navigation-link_disabled"]}`}
        >
          Επεξεργασία
        </Link>
      </nav>
      <Outlet />
    </>
  );
};

export default UomConcert;
