import { FC, ChangeEvent, useState } from "react";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";

import { Language } from "../../../interfaces/Language";

import styles from "./DiscographyInfoForm.module.css";

interface DiscographyInfoFormProps {
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  yearError: string;
  setYearError: React.Dispatch<React.SetStateAction<string>>;
  yearErrorMessage: string | null;
  yearIsValid: boolean;
  link: string | null;
  setLink: React.Dispatch<React.SetStateAction<string | null>>;
  linkError: string;
  setLinkError: React.Dispatch<React.SetStateAction<string>>;
  linkErrorMessage: string | null;
  linkIsValid: boolean;
  publisherEl: string;
  setPublisherEl: React.Dispatch<React.SetStateAction<string>>;
  publisherElError: string;
  setPublisherElError: React.Dispatch<React.SetStateAction<string>>;
  publisherElErrorMessage: string | null;
  publisherElIsValid: boolean;
  publisherEn: string;
  setPublisherEn: React.Dispatch<React.SetStateAction<string>>;
  publisherEnError: string;
  setPublisherEnError: React.Dispatch<React.SetStateAction<string>>;
  publisherEnErrorMessage: string | null;
  publisherEnIsValid: boolean;
  publisherDe: string;
  setPublisherDe: React.Dispatch<React.SetStateAction<string>>;
  publisherDeError: string;
  setPublisherDeError: React.Dispatch<React.SetStateAction<string>>;
  publisherDeErrorMessage: string | null;
  publisherDeIsValid: boolean;
  titleEl: string;
  setTitleEl: React.Dispatch<React.SetStateAction<string>>;
  titleElError: string;
  setTitleElError: React.Dispatch<React.SetStateAction<string>>;
  titleElErrorMessage: string | null;
  titleElIsValid: boolean;
  titleEn: string;
  setTitleEn: React.Dispatch<React.SetStateAction<string>>;
  titleEnError: string;
  setTitleEnError: React.Dispatch<React.SetStateAction<string>>;
  titleEnErrorMessage: string | null;
  titleEnIsValid: boolean;
  titleDe: string;
  setTitleDe: React.Dispatch<React.SetStateAction<string>>;
  titleDeError: string;
  setTitleDeError: React.Dispatch<React.SetStateAction<string>>;
  titleDeErrorMessage: string | null;
  titleDeIsValid: boolean;
  subtitleEl: string;
  setSubtitleEl: React.Dispatch<React.SetStateAction<string>>;
  subtitleElError: string;
  setSubtitleElError: React.Dispatch<React.SetStateAction<string>>;
  subtitleElErrorMessage: string | null;
  subtitleElIsValid: boolean;
  subtitleEn: string;
  setSubtitleEn: React.Dispatch<React.SetStateAction<string>>;
  subtitleEnError: string;
  setSubtitleEnError: React.Dispatch<React.SetStateAction<string>>;
  subtitleEnErrorMessage: string | null;
  subtitleEnIsValid: boolean;
  subtitleDe: string;
  setSubtitleDe: React.Dispatch<React.SetStateAction<string>>;
  subtitleDeError: string;
  setSubtitleDeError: React.Dispatch<React.SetStateAction<string>>;
  subtitleDeErrorMessage: string | null;
  subtitleDeIsValid: boolean;
  descriptionEl: string[] | null;
  setDescriptionEl: React.Dispatch<React.SetStateAction<string[] | null>>;
  descriptionElError: string;
  setDescriptionElError: React.Dispatch<React.SetStateAction<string>>;
  descriptionElErrorMessage: string | null;
  descriptionElIsValid: boolean;
  descriptionEn: string[] | null;
  setDescriptionEn: React.Dispatch<React.SetStateAction<string[] | null>>;
  descriptionEnError: string;
  setDescriptionEnError: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnErrorMessage: string | null;
  descriptionEnIsValid: boolean;
  descriptionDe: string[] | null;
  setDescriptionDe: React.Dispatch<React.SetStateAction<string[] | null>>;
  descriptionDeError: string;
  setDescriptionDeError: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeErrorMessage: string | null;
  descriptionDeIsValid: boolean;
  media: string[] | null;
  setMedia: React.Dispatch<React.SetStateAction<string[] | null>>;
  mediaError: string;
  setMediaError: React.Dispatch<React.SetStateAction<string>>;
  mediaErrorMessage: string | null;
  mediaIsValid: boolean;
  isLoading: boolean;
}

const DiscographyInfoForm: FC<DiscographyInfoFormProps> = ({
  year,
  setYear,
  yearError,
  setYearError,
  yearErrorMessage,
  yearIsValid,
  link,
  setLink,
  linkError,
  setLinkError,
  linkErrorMessage,
  linkIsValid,
  publisherEl,
  setPublisherEl,
  publisherElError,
  setPublisherElError,
  publisherElErrorMessage,
  publisherElIsValid,
  publisherEn,
  setPublisherEn,
  publisherEnError,
  setPublisherEnError,
  publisherEnErrorMessage,
  publisherEnIsValid,
  publisherDe,
  setPublisherDe,
  publisherDeError,
  setPublisherDeError,
  publisherDeErrorMessage,
  publisherDeIsValid,
  titleEl,
  setTitleEl,
  titleElError,
  setTitleElError,
  titleElErrorMessage,
  titleElIsValid,
  titleEn,
  setTitleEn,
  titleEnError,
  setTitleEnError,
  titleEnErrorMessage,
  titleEnIsValid,
  titleDe,
  setTitleDe,
  titleDeError,
  setTitleDeError,
  titleDeErrorMessage,
  titleDeIsValid,
  subtitleEl,
  setSubtitleEl,
  subtitleElError,
  setSubtitleElError,
  subtitleElErrorMessage,
  subtitleElIsValid,
  subtitleEn,
  setSubtitleEn,
  subtitleEnError,
  setSubtitleEnError,
  subtitleEnErrorMessage,
  subtitleEnIsValid,
  subtitleDe,
  setSubtitleDe,
  subtitleDeError,
  setSubtitleDeError,
  subtitleDeErrorMessage,
  subtitleDeIsValid,
  descriptionEl,
  setDescriptionEl,
  descriptionElError,
  setDescriptionElError,
  descriptionElErrorMessage,
  descriptionElIsValid,
  descriptionEn,
  setDescriptionEn,
  descriptionEnError,
  setDescriptionEnError,
  descriptionEnErrorMessage,
  descriptionEnIsValid,
  descriptionDe,
  setDescriptionDe,
  descriptionDeError,
  setDescriptionDeError,
  descriptionDeErrorMessage,
  descriptionDeIsValid,
  media,
  setMedia,
  mediaError,
  setMediaError,
  mediaErrorMessage,
  mediaIsValid,
  isLoading,
}) => {
  const [language, setLanguage] = useState<Language>(Language.GREEK);

  const yearChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (yearError !== "") setYearError("");
    setYear(event.target.value);
  };

  const yearBlurHandler = () => {
    if (!yearIsValid && yearErrorMessage) {
      setYearError(yearErrorMessage);
    } else {
      setYearError("");
    }
  };

  const linkChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (linkError !== "") setLinkError("");
    setLink(event.target.value);
  };

  const linkBlurHandler = () => {
    if (!linkIsValid && linkErrorMessage) {
      setLinkError(linkErrorMessage);
    } else {
      setLinkError("");
    }
  };

  const publisherElChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (publisherElError !== "") setPublisherElError("");
    setPublisherEl(event.target.value);
  };

  const publisherElBlurHandler = () => {
    if (!publisherElIsValid && publisherElErrorMessage) {
      setPublisherElError(publisherElErrorMessage);
    } else {
      setPublisherElError("");
    }
  };

  const publisherEnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (publisherEnError !== "") setPublisherEnError("");
    setPublisherEn(event.target.value);
  };

  const publisherEnBlurHandler = () => {
    if (!publisherEnIsValid && publisherEnErrorMessage) {
      setPublisherEnError(publisherEnErrorMessage);
    } else {
      setPublisherEnError("");
    }
  };

  const publisherDeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (publisherDeError !== "") setPublisherDeError("");
    setPublisherDe(event.target.value);
  };

  const publisherDeBlurHandler = () => {
    if (!publisherDeIsValid && publisherDeErrorMessage) {
      setPublisherDeError(publisherDeErrorMessage);
    } else {
      setPublisherDeError("");
    }
  };

  const titleElChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (titleElError !== "") setTitleElError("");
    setTitleEl(event.target.value);
  };

  const titleElBlurHandler = () => {
    if (!titleElIsValid && titleElErrorMessage) {
      setTitleElError(titleElErrorMessage);
    } else {
      setTitleElError("");
    }
  };

  const titleEnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (titleEnError !== "") setTitleEnError("");
    setTitleEn(event.target.value);
  };

  const titleEnBlurHandler = () => {
    if (!titleEnIsValid && titleEnErrorMessage) {
      setTitleEnError(titleEnErrorMessage);
    } else {
      setTitleEnError("");
    }
  };

  const titleDeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (titleDeError !== "") setTitleDeError("");
    setTitleDe(event.target.value);
  };

  const titleDeBlurHandler = () => {
    if (!titleDeIsValid && titleDeErrorMessage) {
      setTitleDeError(titleDeErrorMessage);
    } else {
      setTitleDeError("");
    }
  };

  const subtitleElChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (subtitleElError !== "") setSubtitleElError("");
    setSubtitleEl(event.target.value);
  };

  const subtitleElBlurHandler = () => {
    if (!subtitleElIsValid && subtitleElErrorMessage) {
      setSubtitleElError(subtitleElErrorMessage);
    } else {
      setSubtitleElError("");
    }
  };

  const subtitleEnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (subtitleEnError !== "") setSubtitleEnError("");
    setSubtitleEn(event.target.value);
  };

  const subtitleEnBlurHandler = () => {
    if (!subtitleEnIsValid && subtitleEnErrorMessage) {
      setSubtitleEnError(subtitleEnErrorMessage);
    } else {
      setSubtitleEnError("");
    }
  };

  const subtitleDeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (subtitleDeError !== "") setSubtitleDeError("");
    setSubtitleDe(event.target.value);
  };

  const subtitleDeBlurHandler = () => {
    if (!subtitleDeIsValid && subtitleDeErrorMessage) {
      setSubtitleDeError(subtitleDeErrorMessage);
    } else {
      setSubtitleDeError("");
    }
  };

  const descriptionElAddHandler = () => {
    setDescriptionEl((current) => {
      if (!current) return [""];
      const newDescription = current.slice();
      newDescription.push("");
      return newDescription;
    });
  };

  const descriptionElRemoveHandler = (idx: number) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1);
      return newDescription;
    });
  };

  const descriptionElChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number
  ) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1, event.target.value);
      return newDescription;
    });
  };

  const descriptionElBlurHandler = () => {
    if (!descriptionElIsValid && descriptionElErrorMessage) {
      setDescriptionElError(descriptionElErrorMessage);
    } else {
      setDescriptionElError("");
    }
  };

  const descriptionEnAddHandler = () => {
    setDescriptionEn((current) => {
      if (!current) return [""];
      const newDescription = current.slice();
      newDescription.push("");
      return newDescription;
    });
  };

  const descriptionEnRemoveHandler = (idx: number) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1);
      return newDescription;
    });
  };

  const descriptionEnChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number
  ) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1, event.target.value);
      return newDescription;
    });
  };

  const descriptionEnBlurHandler = () => {
    if (!descriptionEnIsValid && descriptionEnErrorMessage) {
      setDescriptionEnError(descriptionEnErrorMessage);
    } else {
      setDescriptionEnError("");
    }
  };

  const descriptionDeAddHandler = () => {
    setDescriptionDe((current) => {
      if (!current) return [""];
      const newDescription = current.slice();
      newDescription.push("");
      return newDescription;
    });
  };

  const descriptionDeRemoveHandler = (idx: number) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1);
      return newDescription;
    });
  };

  const descriptionDeChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>,
    idx: number
  ) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newDescription = current.slice();
      newDescription.splice(idx, 1, event.target.value);
      return newDescription;
    });
  };

  const descriptionDeBlurHandler = () => {
    if (!descriptionDeIsValid && descriptionDeErrorMessage) {
      setDescriptionDeError(descriptionDeErrorMessage);
    } else {
      setDescriptionDeError("");
    }
  };

  const mediaAddHandler = () => {
    setMedia((current) => {
      if (!current) return [""];
      const newMedia = current.slice();
      newMedia.push("");
      return newMedia;
    });
  };

  const mediaRemoveHandler = (idx: number) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1);
      return newMedia;
    });
  };

  const mediaChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (mediaError !== "") setMediaError("");
    setMedia((current) => {
      if (!current || idx < 0 || idx >= current.length) return current;
      const newMedia = current.slice();
      newMedia.splice(idx, 1, event.target.value);
      return newMedia;
    });
  };

  const mediaBlurHandler = () => {
    if (!mediaIsValid && mediaErrorMessage) {
      setMediaError(mediaErrorMessage);
    } else {
      setMediaError("");
    }
  };

  const languageChangeHandler = (lang: Language) => setLanguage(lang);

  return (
    <>
      {/* Year input */}
      <div className={styles["form_field"]}>
        <label htmlFor="year-input">Έτος Κυκλοφορίας</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="year-input"
            type="number"
            min="1990"
            max={`${new Date().getFullYear() + 5}`}
            disabled={isLoading}
            value={year}
            onChange={yearChangeHandler}
            onBlur={yearBlurHandler}
            required
            aria-required
          />
        </div>
        {yearError && yearError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{yearError}</p>
        )}
      </div>

      {/* Link input */}
      <div className={styles["form_field"]}>
        <label htmlFor="link-input">Link Δίσκου (προαιρετικό)</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="link-input"
            type="text"
            placeholder="π.χ. https://www.uwematschkepianist.com"
            disabled={isLoading}
            value={link || ""}
            onChange={linkChangeHandler}
            onBlur={linkBlurHandler}
          />
        </div>
        {linkError && linkError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{linkError}</p>
        )}
      </div>

      {/* Select Language */}
      <div className={styles["language-selection"]}>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GREEK)}
          className={`${
            language === Language.GREEK
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${(!titleEnIsValid || !descriptionElIsValid) && styles["has-error"]}`}
        >
          Ελληνικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.ENGLISH)}
          className={`${
            language === Language.ENGLISH
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${(!titleEnIsValid || !descriptionEnIsValid) && styles["has-error"]}`}
        >
          Αγγλικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GERMAN)}
          className={`${
            language === Language.GERMAN
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
              ${
                (!titleDeIsValid || !descriptionDeIsValid) &&
                styles["has-error"]
              }`}
        >
          Γερμανικά
        </button>
      </div>

      {/* Publisher Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="publisher-el-input">Εκδότης Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="publisher-el-input"
              type="text"
              placeholder="π.χ. ΠΑΜΑΚ"
              disabled={isLoading}
              value={publisherEl}
              onChange={publisherElChangeHandler}
              onBlur={publisherElBlurHandler}
              required
              aria-required
            />
          </div>
          {publisherElError && publisherElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{publisherElError}</p>
          )}
        </div>
      )}

      {/* Publisher English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="publisher-en-input">Εκδότης Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="publisher-en-input"
              type="text"
              placeholder="π.χ. ΠΑΜΑΚ"
              disabled={isLoading}
              value={publisherEn}
              onChange={publisherEnChangeHandler}
              onBlur={publisherEnBlurHandler}
              required
              aria-required
            />
          </div>
          {publisherEnError && publisherEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{publisherEnError}</p>
          )}
        </div>
      )}
      {/* Publisher English input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="publisher-de-input">Εκδότης Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="publisher-de-input"
              type="text"
              placeholder="π.χ. ΠΑΜΑΚ"
              disabled={isLoading}
              value={publisherDe}
              onChange={publisherDeChangeHandler}
              onBlur={publisherDeBlurHandler}
              required
              aria-required
            />
          </div>
          {publisherDeError && publisherDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{publisherDeError}</p>
          )}
        </div>
      )}

      {/* Title Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="title-el-input">Τίτλος Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="title-el-input"
              type="text"
              placeholder="π.χ. Δίσκος 1"
              disabled={isLoading}
              value={titleEl}
              onChange={titleElChangeHandler}
              onBlur={titleElBlurHandler}
              required
              aria-required
            />
          </div>
          {titleElError && titleElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{titleElError}</p>
          )}
        </div>
      )}

      {/* Title English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="title-en-input">Τίτλος Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="title-en-input"
              type="text"
              placeholder="π.χ. Δίσκος 1"
              disabled={isLoading}
              value={titleEn}
              onChange={titleEnChangeHandler}
              onBlur={titleEnBlurHandler}
              required
              aria-required
            />
          </div>
          {titleEnError && titleEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{titleEnError}</p>
          )}
        </div>
      )}

      {/* Title German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="title-de-input">Τίτλος Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="title-de-input"
              type="text"
              placeholder="π.χ. Δίσκος 1"
              disabled={isLoading}
              value={titleDe}
              onChange={titleDeChangeHandler}
              onBlur={titleDeBlurHandler}
              required
              aria-required
            />
          </div>
          {titleDeError && titleDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{titleDeError}</p>
          )}
        </div>
      )}

      {/* Subitle Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="subtitle-el-input">
            Δευτερεύον Τίτλος Ελληνικά (προαιρετικό)
          </label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="subtitle-el-input"
              type="text"
              placeholder="π.χ. Συμμετέχει ο/η ..."
              disabled={isLoading}
              value={subtitleEl}
              onChange={subtitleElChangeHandler}
              onBlur={subtitleElBlurHandler}
            />
          </div>
          {subtitleElError && subtitleElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{subtitleElError}</p>
          )}
        </div>
      )}

      {/* Subtitle English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="subtitle-en-input">
            Δευτερεύον Τίτλος Αγγλικά (προαιρετικό)
          </label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="subtitle-en-input"
              type="text"
              placeholder="π.χ. Δίσκος 1"
              disabled={isLoading}
              value={subtitleEn}
              onChange={subtitleEnChangeHandler}
              onBlur={subtitleEnBlurHandler}
            />
          </div>
          {subtitleEnError && subtitleEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{subtitleEnError}</p>
          )}
        </div>
      )}

      {/* Subtitle German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="subtitle-de-input">
            Δευτερεύον Τίτλος Γερμανικά (προαιρετικό)
          </label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <input
              id="subtitle-de-input"
              type="text"
              placeholder="π.χ. Δίσκος 1"
              disabled={isLoading}
              value={subtitleDe}
              onChange={subtitleDeChangeHandler}
              onBlur={subtitleDeBlurHandler}
            />
          </div>
          {subtitleDeError && subtitleDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{subtitleDeError}</p>
          )}
        </div>
      )}

      {/* Description Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="media-input">Περιγραφή Ελληνικά</label>
          {descriptionEl &&
            descriptionEl.length > 0 &&
            descriptionEl.map((d, idx) => (
              <div
                key={`descrEl-${idx}`}
                className={
                  isLoading
                    ? styles["form_field_input-disabled"]
                    : styles["form_field_input"]
                }
              >
                <textarea
                  id={`descrEl-input-${idx}`}
                  rows={5}
                  placeholder="Κείμενο παραγράφου"
                  disabled={isLoading}
                  value={d}
                  onChange={(e) => descriptionElChangeHandler(e, idx)}
                  onBlur={descriptionElBlurHandler}
                  required
                  aria-required
                />
                <span className={styles["form_field_input-icon_end"]}>
                  <AiFillDelete
                    onClick={() => descriptionElRemoveHandler(idx)}
                  />
                </span>
              </div>
            ))}
          <button
            type="button"
            onClick={descriptionElAddHandler}
            disabled={isLoading}
            className={
              isLoading
                ? styles["media-add-button-disabled"]
                : styles["media-add-button"]
            }
          >
            Προσθέστε νέα παράγραφο στην περιγραφή
            <span className={styles["form_field_input-icon_end"]}>
              <AiFillPlusCircle />
            </span>
          </button>
          {descriptionElError && descriptionElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionElError}</p>
          )}
        </div>
      )}

      {/* Description English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="media-input">Περιγραφή Αγγλικά</label>
          {descriptionEn &&
            descriptionEn.length > 0 &&
            descriptionEn.map((d, idx) => (
              <div
                key={`descrEn-${idx}`}
                className={
                  isLoading
                    ? styles["form_field_input-disabled"]
                    : styles["form_field_input"]
                }
              >
                <textarea
                  id={`descrEn-input-${idx}`}
                  rows={5}
                  placeholder="Κείμενο παραγράφου"
                  disabled={isLoading}
                  value={d}
                  onChange={(e) => descriptionEnChangeHandler(e, idx)}
                  onBlur={descriptionEnBlurHandler}
                  required
                  aria-required
                />
                <span className={styles["form_field_input-icon_end"]}>
                  <AiFillDelete
                    onClick={() => descriptionEnRemoveHandler(idx)}
                  />
                </span>
              </div>
            ))}
          <button
            type="button"
            onClick={descriptionEnAddHandler}
            disabled={isLoading}
            className={
              isLoading
                ? styles["media-add-button-disabled"]
                : styles["media-add-button"]
            }
          >
            Προσθέστε νέα παράγραφο στην περιγραφή
            <span className={styles["form_field_input-icon_end"]}>
              <AiFillPlusCircle />
            </span>
          </button>
          {descriptionEnError && descriptionEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionEnError}</p>
          )}
        </div>
      )}

      {/* Description German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="media-input">Περιγραφή Γερμανικά</label>
          {descriptionDe &&
            descriptionDe.length > 0 &&
            descriptionDe.map((d, idx) => (
              <div
                key={`descrDe-${idx}`}
                className={
                  isLoading
                    ? styles["form_field_input-disabled"]
                    : styles["form_field_input"]
                }
              >
                <textarea
                  id={`descrDe-input-${idx}`}
                  rows={5}
                  placeholder="Κείμενο παραγράφου"
                  disabled={isLoading}
                  value={d}
                  onChange={(e) => descriptionDeChangeHandler(e, idx)}
                  onBlur={descriptionDeBlurHandler}
                  required
                  aria-required
                />
                <span className={styles["form_field_input-icon_end"]}>
                  <AiFillDelete
                    onClick={() => descriptionDeRemoveHandler(idx)}
                  />
                </span>
              </div>
            ))}
          <button
            type="button"
            onClick={descriptionDeAddHandler}
            disabled={isLoading}
            className={
              isLoading
                ? styles["media-add-button-disabled"]
                : styles["media-add-button"]
            }
          >
            Προσθέστε νέα παράγραφο στην περιγραφή
            <span className={styles["form_field_input-icon_end"]}>
              <AiFillPlusCircle />
            </span>
          </button>
          {descriptionDeError && descriptionDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionDeError}</p>
          )}
        </div>
      )}

      {/* Media input */}
      <div className={styles["form_field"]}>
        <label htmlFor="media-input">Media (προαιρετικό)</label>
        {media &&
          media.length > 0 &&
          media.map((m, idx) => (
            <div
              key={`media-${idx}`}
              className={
                isLoading
                  ? styles["form_field_input-disabled"]
                  : styles["form_field_input"]
              }
            >
              <input
                id={`media-input-${idx}`}
                type="text"
                placeholder="π.χ. https://www.youtube.com/..."
                disabled={isLoading}
                value={m}
                onChange={(e) => mediaChangeHandler(e, idx)}
                onBlur={mediaBlurHandler}
                required
                aria-required
              />
              <span className={styles["form_field_input-icon_end"]}>
                <AiFillDelete onClick={() => mediaRemoveHandler(idx)} />
              </span>
            </div>
          ))}
        <button
          type="button"
          onClick={mediaAddHandler}
          disabled={isLoading}
          className={
            isLoading
              ? styles["media-add-button-disabled"]
              : styles["media-add-button"]
          }
        >
          Προσθέστε links για περισσότερα Media
          <span className={styles["form_field_input-icon_end"]}>
            <AiFillPlusCircle />
          </span>
        </button>
        {mediaError && mediaError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{mediaError}</p>
        )}
      </div>
    </>
  );
};

export default DiscographyInfoForm;
