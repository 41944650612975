import { FC, useContext, useState, useEffect } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import classNames from "classnames";

import { DiscographyBrief } from "../../../interfaces/Discography";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { DiscographyCard } from "..";

import styles from "./DiscographyGrid.module.css";

interface DiscographyGridProps {
  discography: DiscographyBrief[];
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const DiscographyGrid: FC<DiscographyGridProps> = ({
  discography: initialDiscography,
  onRefresh,
}) => {
  const navigate: NavigateFunction = useNavigate();

  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  const [discography, setDiscography] = useState<DiscographyBrief[]>([]);

  // whenever initial list of discography is changed (e.g. refresh or response from API), update the list of discography
  useEffect(() => {
    setDiscography(initialDiscography);
  }, [initialDiscography]);

  // handle request to delete a disc
  const deleteHandler = (year: number) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/discography/delete/${year}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  // navigate to edit discography page
  const editHandler = (year: number) => {
    navigate(`/discography/edit/${year}`);
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {discography && discography.length > 0 && (
        <div className={classNames(styles["discography-grid"])}>
          {discography.map((disc, index) => (
            <div key={`t-${disc.year}`} style={{ margin: "8px 0" }}>
              <DiscographyCard
                discography={disc}
                onDelete={deleteHandler}
                onEdit={editHandler}
                isLoading={isLoading}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DiscographyGrid;
