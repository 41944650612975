import { FC, ChangeEvent, useState } from "react";

import { Language } from "../../../interfaces/Language";

import styles from "./UomContestInfoForm.module.css";

interface UomContestInfoFormProps {
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  yearError: string;
  setYearError: React.Dispatch<React.SetStateAction<string>>;
  yearErrorMessage: string | null;
  yearIsValid: boolean;
  descriptionEl: string;
  setDescriptionEl: React.Dispatch<React.SetStateAction<string>>;
  descriptionElError: string;
  setDescriptionElError: React.Dispatch<React.SetStateAction<string>>;
  descriptionElErrorMessage: string | null;
  descriptionElIsValid: boolean;
  descriptionEn: string;
  setDescriptionEn: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnError: string;
  setDescriptionEnError: React.Dispatch<React.SetStateAction<string>>;
  descriptionEnErrorMessage: string | null;
  descriptionEnIsValid: boolean;
  descriptionDe: string;
  setDescriptionDe: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeError: string;
  setDescriptionDeError: React.Dispatch<React.SetStateAction<string>>;
  descriptionDeErrorMessage: string | null;
  descriptionDeIsValid: boolean;
  link: string | null;
  setLink: React.Dispatch<React.SetStateAction<string | null>>;
  linkError: string;
  setLinkError: React.Dispatch<React.SetStateAction<string>>;
  linkErrorMessage: string | null;
  linkIsValid: boolean;
  isLoading: boolean;
}

const UomContestInfoForm: FC<UomContestInfoFormProps> = ({
  year,
  setYear,
  yearError,
  setYearError,
  yearErrorMessage,
  yearIsValid,
  descriptionEl,
  setDescriptionEl,
  descriptionElError,
  setDescriptionElError,
  descriptionElErrorMessage,
  descriptionElIsValid,
  descriptionEn,
  setDescriptionEn,
  descriptionEnError,
  setDescriptionEnError,
  descriptionEnErrorMessage,
  descriptionEnIsValid,
  descriptionDe,
  setDescriptionDe,
  descriptionDeError,
  setDescriptionDeError,
  descriptionDeErrorMessage,
  descriptionDeIsValid,
  link,
  setLink,
  linkError,
  setLinkError,
  linkErrorMessage,
  linkIsValid,
  isLoading,
}) => {
  const [language, setLanguage] = useState<Language>(Language.GREEK);

  const yearChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (yearError !== "") setYearError("");
    setYear(event.target.value);
  };

  const yearBlurHandler = () => {
    if (!yearIsValid && yearErrorMessage) {
      setYearError(yearErrorMessage);
    } else {
      setYearError("");
    }
  };

  const descriptionElChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionElError !== "") setDescriptionElError("");
    setDescriptionEl(event.target.value);
  };

  const descriptionElBlurHandler = () => {
    if (!descriptionElIsValid && descriptionElErrorMessage) {
      setDescriptionElError(descriptionElErrorMessage);
    } else {
      setDescriptionElError("");
    }
  };

  const descriptionEnChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionEnError !== "") setDescriptionEnError("");
    setDescriptionEn(event.target.value);
  };

  const descriptionEnBlurHandler = () => {
    if (!descriptionEnIsValid && descriptionEnErrorMessage) {
      setDescriptionEnError(descriptionEnErrorMessage);
    } else {
      setDescriptionEnError("");
    }
  };

  const descriptionDeChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (descriptionDeError !== "") setDescriptionDeError("");
    setDescriptionDe(event.target.value);
  };

  const descriptionDeBlurHandler = () => {
    if (!descriptionDeIsValid && descriptionDeErrorMessage) {
      setDescriptionDeError(descriptionDeErrorMessage);
    } else {
      setDescriptionDeError("");
    }
  };

  const linkChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (linkError !== "") setLinkError("");
    setLink(event.target.value);
  };

  const linkBlurHandler = () => {
    if (!linkIsValid && linkErrorMessage) {
      setLinkError(linkErrorMessage);
    } else {
      setLinkError("");
    }
  };

  const languageChangeHandler = (lang: Language) => setLanguage(lang);

  return (
    <>
      {/* Year input */}
      <div className={styles["form_field"]}>
        <label htmlFor="year-input">Έτος Διαγωνισμού</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="year-input"
            type="number"
            min="1990"
            max={`${new Date().getFullYear() + 5}`}
            disabled={isLoading}
            value={year}
            onChange={yearChangeHandler}
            onBlur={yearBlurHandler}
            required
            aria-required
          />
        </div>
        {yearError && yearError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{yearError}</p>
        )}
      </div>

      {/* Select Language */}
      <div className={styles["language-selection"]}>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GREEK)}
          className={`${
            language === Language.GREEK
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionElIsValid && styles["has-error"]}`}
        >
          Ελληνικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.ENGLISH)}
          className={`${
            language === Language.ENGLISH
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
          ${!descriptionEnIsValid && styles["has-error"]}`}
        >
          Αγγλικά
        </button>
        <button
          type="button"
          onClick={() => languageChangeHandler(Language.GERMAN)}
          className={`${
            language === Language.GERMAN
              ? styles["language-selection-button_active"]
              : styles["language-selection-button_inactive"]
          }
              ${!descriptionDeIsValid && styles["has-error"]}`}
        >
          Γερμανικά
        </button>
      </div>

      {/* Description Greek input */}
      {language === Language.GREEK && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-el-input">Περιγραφή Ελληνικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-el-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEl}
              onChange={descriptionElChangeHandler}
              onBlur={descriptionElBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionElError && descriptionElError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionElError}</p>
          )}
        </div>
      )}

      {/* Description English input */}
      {language === Language.ENGLISH && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-en-input">Περιγραφή Αγγλικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-en-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionEn}
              onChange={descriptionEnChangeHandler}
              onBlur={descriptionEnBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionEnError && descriptionEnError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionEnError}</p>
          )}
        </div>
      )}

      {/* Description German input */}
      {language === Language.GERMAN && (
        <div className={styles["form_field"]}>
          <label htmlFor="description-de-input">Περιγραφή Γερμανικά</label>
          <div
            className={
              isLoading
                ? styles["form_field_input-disabled"]
                : styles["form_field_input"]
            }
          >
            <textarea
              id="description-de-input"
              rows={4}
              placeholder="π.χ. Συναυλία πιανιστικών συνόλων..."
              disabled={isLoading}
              value={descriptionDe}
              onChange={descriptionDeChangeHandler}
              onBlur={descriptionDeBlurHandler}
              required
              aria-required
            />
          </div>
          {descriptionDeError && descriptionDeError.trim() !== "" && (
            <p className={styles["form_field_error"]}>{descriptionDeError}</p>
          )}
        </div>
      )}

      {/* Link input */}
      <div className={styles["form_field"]}>
        <label htmlFor="link-input">Link (προαιρετικό)</label>
        <div
          className={
            isLoading
              ? styles["form_field_input-disabled"]
              : styles["form_field_input"]
          }
        >
          <input
            id="link-input"
            type="text"
            placeholder="π.χ. https://www.uwematschkepianist.com"
            disabled={isLoading}
            value={link || ""}
            onChange={linkChangeHandler}
            onBlur={linkBlurHandler}
          />
        </div>
        {linkError && linkError.trim() !== "" && (
          <p className={styles["form_field_error"]}>{linkError}</p>
        )}
      </div>
    </>
  );
};

export default UomContestInfoForm;
