import { FC, useContext, useState, useEffect } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import classNames from "classnames";

import { IUomConcertBrief } from "../../../interfaces/IUomConcert";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { UomConcertCard } from "..";

import styles from "./UomConcertGrid.module.css";

interface UomConcertGridProps {
  concerts: IUomConcertBrief[];
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const UomConcertGrid: FC<UomConcertGridProps> = ({
  concerts: initialConcerts,
  onRefresh,
}) => {
  const navigate: NavigateFunction = useNavigate();

  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  const [concerts, setConcerts] = useState<IUomConcertBrief[]>([]);

  // whenever initial list of UOM concerts is changed (e.g. refresh or response from API), update the list of UOM concerts
  useEffect(() => {
    setConcerts(initialConcerts);
  }, [initialConcerts]);

  // handle request to delete a concert
  const deleteHandler = (id: string) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/uom/concert/delete/${id}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  // navigate to edit UOM concert page
  const editHandler = (id: string) => {
    navigate(`/uom/concerts/edit/${id}`);
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {concerts && concerts.length > 0 && (
        <div className={classNames(styles["concerts-grid"])}>
          {concerts.map((concert, index) => (
            <div key={`c-${concert.id}`} style={{ margin: "8px 0" }}>
              <UomConcertCard
                concert={concert}
                onDelete={deleteHandler}
                onEdit={editHandler}
                isLoading={isLoading}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UomConcertGrid;
