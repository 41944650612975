import {
  FC,
  useState,
  useContext,
  useEffect,
  useCallback,
  FormEvent,
} from "react";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { validateText, validateDate, validateUrl } from "../../../utils/input";

import {
  UomConcertInfoForm,
  UomConcertPdfForm,
  UomConcertPdfDisplay,
  UomConcertImageForm,
  UomConcertImageDisplay,
} from "../index";

import { IUomConcert } from "../../../interfaces/IUomConcert";

import styles from "./EditUomConcertForm.module.css";

interface ApiResponse {
  message: string;
}

const EditUomConcertForm: FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { auth } = useContext(AuthContext) as AuthContextType;
  const params = useParams();
  const { concertId } = params;

  // useHttp custom hook
  const { isLoading, error, sendRequest } = useHttp();

  if (!concertId || concertId.trim() === "")
    navigate("/uom/concerts", { replace: true });

  const [concertDateFrom, setConcertDateFrom] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [concertDateTo, setConcertDateTo] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [concertDescriptionEl, setConcertDescriptionEl] = useState<string>("");
  const [concertDescriptionEn, setConcertDescriptionEn] = useState<string>("");
  const [concertDescriptionDe, setConcertDescriptionDe] = useState<string>("");
  const [concertMedia, setConcertMedia] = useState<string[] | null>([]);
  const [concertIsRecital, setConcertIsRecital] = useState<boolean>(false);
  const [concertImage, setConcertImage] = useState<string | null | undefined>(
    null
  );
  const [concertFile, setConcertFile] = useState<string | null | undefined>(
    null
  );

  const [concertDateFromError, setConcertDateFromError] = useState<string>("");
  const [concertDateToError, setConcertDateToError] = useState<string>("");
  const [concertDescriptionElError, setConcertDescriptionElError] =
    useState<string>("");
  const [concertDescriptionEnError, setConcertDescriptionEnError] =
    useState<string>("");
  const [concertDescriptionDeError, setConcertDescriptionDeError] =
    useState<string>("");
  const [concertMediaError, setConcertMediaError] = useState<string>("");

  const dateFromErrorMessage = validateDate(concertDateFrom);
  const dateFromIsValid = dateFromErrorMessage == null;
  const dateToErrorMessage = validateDate(concertDateTo);
  const dateToIsValid = dateToErrorMessage == null;
  const descriptionElErrorMessage = validateText(concertDescriptionEl, 5000);
  const descriptionElIsValid = descriptionElErrorMessage == null;
  const descriptionEnErrorMessage = validateText(concertDescriptionEn, 5000);
  const descriptionEnIsValid = descriptionEnErrorMessage == null;
  const descriptionDeErrorMessage = validateText(concertDescriptionDe, 5000);
  const descriptionDeIsValid = descriptionDeErrorMessage == null;

  let mediaErrorMessage = null;
  if (concertMedia && concertMedia.length > 0) {
    for (let mediaInput of concertMedia) {
      mediaErrorMessage = validateUrl(mediaInput);
      if (mediaErrorMessage != null) break;
    }
  }
  const mediaIsValid = mediaErrorMessage == null;

  const formIsValid =
    dateFromIsValid &&
    dateToIsValid &&
    descriptionElIsValid &&
    descriptionEnIsValid &&
    descriptionDeIsValid &&
    mediaIsValid;

  // Get concert's details from API and set state
  const getConcertDetails = useCallback(() => {
    // transform API response
    const transformResponse = (response: IUomConcert) => {
      setConcertDateFrom(response.dateFrom);
      setConcertDateTo(response.dateTo);
      setConcertDescriptionEl(response.descriptionEl);
      setConcertDescriptionEn(response.descriptionEn);
      setConcertDescriptionDe(response.descriptionDe);
      setConcertIsRecital(response.isRecital);
      setConcertImage(response.image);
      setConcertFile(response.file);
      setConcertMedia(response.media);
    };

    // send GET request to API's route /admin/uom/concerts/info/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/concert/info/${concertId}`;
    sendRequest({ url, method: "GET", token: auth.token }, transformResponse);
  }, [
    concertId,
    setConcertDateFrom,
    setConcertDateTo,
    setConcertDescriptionEl,
    setConcertDescriptionEn,
    setConcertDescriptionDe,
    setConcertIsRecital,
    setConcertImage,
    setConcertFile,
    setConcertMedia,
    sendRequest,
    auth.token,
  ]);

  useEffect(() => {
    getConcertDetails();
  }, [getConcertDetails]);

  const editConcert = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!formIsValid) return;

    // transform API response
    const transformResponse = (response: ApiResponse) => {
      navigate(`/uom/concerts/edit/${concertId}`, {
        replace: true,
      });
    };

    // send PATCH request to API's route /admin/uom/concert/edit/:id
    const url = `${process.env.REACT_APP_API_URL}/admin/uom/concert/edit/${concertId}`;
    sendRequest(
      {
        url,
        method: "PATCH",
        token: auth.token,
        data: {
          dateFrom: concertDateFrom.trim(),
          dateTo: concertDateTo.trim(),
          descriptionEl: concertDescriptionEl?.trim() || "",
          descriptionEn: concertDescriptionEn?.trim() || "",
          descriptionDe: concertDescriptionDe?.trim() || "",
          isRecital: concertIsRecital,
          media:
            concertMedia && concertMedia.length > 0
              ? JSON.stringify(concertMedia)
              : JSON.stringify([]),
        },
      },
      transformResponse
    );
  };

  return (
    <>
      <form className={styles["form_container"]} onSubmit={editConcert}>
        <UomConcertInfoForm
          dateFrom={concertDateFrom}
          setDateFrom={setConcertDateFrom}
          dateFromError={concertDateFromError}
          setDateFromError={setConcertDateFromError}
          dateFromErrorMessage={dateFromErrorMessage}
          dateFromIsValid={dateFromIsValid}
          dateTo={concertDateTo}
          setDateTo={setConcertDateTo}
          dateToError={concertDateToError}
          setDateToError={setConcertDateToError}
          dateToErrorMessage={dateToErrorMessage}
          dateToIsValid={dateToIsValid}
          descriptionEl={concertDescriptionEl}
          setDescriptionEl={setConcertDescriptionEl}
          descriptionElError={concertDescriptionElError}
          setDescriptionElError={setConcertDescriptionElError}
          descriptionElErrorMessage={descriptionElErrorMessage}
          descriptionElIsValid={descriptionElIsValid}
          descriptionEn={concertDescriptionEn}
          setDescriptionEn={setConcertDescriptionEn}
          descriptionEnError={concertDescriptionEnError}
          setDescriptionEnError={setConcertDescriptionEnError}
          descriptionEnErrorMessage={descriptionEnErrorMessage}
          descriptionEnIsValid={descriptionEnIsValid}
          descriptionDe={concertDescriptionDe}
          setDescriptionDe={setConcertDescriptionDe}
          descriptionDeError={concertDescriptionDeError}
          setDescriptionDeError={setConcertDescriptionDeError}
          descriptionDeErrorMessage={descriptionDeErrorMessage}
          descriptionDeIsValid={descriptionDeIsValid}
          media={concertMedia}
          setMedia={setConcertMedia}
          mediaError={concertMediaError}
          setMediaError={setConcertMediaError}
          mediaErrorMessage={mediaErrorMessage}
          mediaIsValid={mediaIsValid}
          isRecital={concertIsRecital}
          setIsRecital={setConcertIsRecital}
          isLoading={isLoading}
        />
        <div className={styles["form_btn_container"]}>
          <button
            type="submit"
            className={styles["form_submit_btn"]}
            disabled={isLoading || !formIsValid}
          >
            Αποθήκευση Αλλαγών
          </button>
        </div>
        {error && error.trim() !== "" && (
          <p className={styles["form_error"]}>{error}</p>
        )}
      </form>
      <UomConcertPdfDisplay
        id={concertId || ""}
        file={concertFile}
        onRefresh={getConcertDetails}
      />
      <UomConcertPdfForm
        id={concertId || ""}
        existingFile={!!concertFile}
        onRefresh={getConcertDetails}
      />
      <UomConcertImageDisplay
        id={concertId || ""}
        image={concertImage}
        onRefresh={getConcertDetails}
      />
      <UomConcertImageForm
        id={concertId || ""}
        existingImage={!!concertImage}
        onRefresh={getConcertDetails}
      />
    </>
  );
};

export default EditUomConcertForm;
