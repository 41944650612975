import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { IconType } from "react-icons";
import { AiOutlineLeft, AiFillTrophy } from "react-icons/ai";
import { BiLogOut, BiLogIn } from "react-icons/bi";
import { GiGrandPiano } from "react-icons/gi";
import {
  BsFillImageFill,
  BsFillCameraVideoFill,
  BsNewspaper,
} from "react-icons/bs";
import { FaCompactDisc, FaUserGraduate } from "react-icons/fa";

import { AuthContext } from "../../context";
import { AuthContextType } from "../../interfaces/AuthContext";

import SidebarIcon from "./SidebarIcon";
import styles from "./Sidebar.module.css";

interface SidebarProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

interface MenuNavigation {
  name: string;
  Icon: IconType;
  gap?: boolean;
  path: string;
}

const Sidebar: FC<SidebarProps> = ({ isOpen, toggleIsOpen }) => {
  const { auth, logout } = useContext(AuthContext) as AuthContextType;

  const navList: MenuNavigation[] = [
    {
      name: "Συναυλίες",
      Icon: GiGrandPiano,
      path: "/concerts",
    },
    {
      name: "Δισκογραφία",
      Icon: FaCompactDisc,
      path: "/discography",
    },
    {
      name: "Συναυλίες Φοιτητών",
      Icon: FaUserGraduate,
      path: "/uom/concerts",
    },
    {
      name: "Επιτυχίες Φοιτητών",
      Icon: AiFillTrophy,
      path: "/uom/contests",
    },
    {
      name: "Gallery",
      Icon: BsFillImageFill,
      path: "/gallery",
    },
    {
      name: "Videos",
      Icon: BsFillCameraVideoFill,
      path: "/videos",
    },
    {
      name: "Τύπος",
      Icon: BsNewspaper,
      path: "/press",
    },
  ];

  return (
    <>
      {/* Open/Close menu icon */}
      <AiOutlineLeft
        className={isOpen ? styles["sidebar-open"] : styles["sidebar-close"]}
        onClick={() => toggleIsOpen()}
      />
      {/* Store logo and name */}
      <div
        className={
          isOpen ? styles["sidebar_logo-open"] : styles["sidebar_logo-close"]
        }
      >
        <h1>
          UWE
          <br />
          MATSCHKE
        </h1>
      </div>
      {/* Navigations */}
      <nav
        className={
          isOpen ? styles["sidebar_links-open"] : styles["sidebar_links-close"]
        }
      >
        {auth.isLoggedIn ? (
          <ul
            className={
              isOpen
                ? styles["sidebar_links_list-open"]
                : styles["sidebar_links_list-close"]
            }
          >
            {navList.map((navItem: MenuNavigation, index: number) => (
              <li
                key={`nav-${index}`}
                style={{ marginTop: navItem.gap ? "8px" : "4px" }}
              >
                <NavLink
                  to={navItem.path}
                  className={({ isActive }) =>
                    classNames(
                      `${
                        isActive
                          ? styles["sidebar_links_list-item_active"]
                          : styles["sidebar_links_list-item_inactive"]
                      }`,
                      `${
                        isOpen
                          ? styles["sidebar_links_list-item-open"]
                          : styles["sidebar_links_list-item-close"]
                      }`
                    )
                  }
                >
                  <SidebarIcon Icon={navItem.Icon} />
                  <span>{navItem.name}</span>
                </NavLink>
              </li>
            ))}
            <li
              className={classNames(
                isOpen
                  ? styles["sidebar_links_list-item-open"]
                  : styles["sidebar_links_list-item-close"],
                styles["sidebar_links_list-item_logout"]
              )}
              style={{ marginTop: "8px" }}
              onClick={logout}
            >
              <SidebarIcon Icon={BiLogOut} />
              <span>Αποσύνδεση</span>
            </li>
          </ul>
        ) : (
          <ul
            className={
              isOpen
                ? styles["sidebar_links_list-open"]
                : styles["sidebar_links_list-close"]
            }
          >
            <li
              className={classNames(
                isOpen
                  ? styles["sidebar_links_list-item-open"]
                  : styles["sidebar_links_list-item-close"],
                styles["sidebar_links_list-item_active"]
              )}
            >
              <SidebarIcon Icon={BiLogIn} />
              <span>Σύνδεση</span>
            </li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Sidebar;
