import { FC, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { DiscographyBrief } from "../../../interfaces/Discography";
import { ModalDialog, ModalDialogActionStyle } from "../..";

import styles from "./DiscographyCard.module.css";

interface DiscographyCardProps {
  discography: DiscographyBrief;
  onDelete: (year: number) => void;
  onEdit: (year: number) => void;
  isLoading: boolean;
}

const DiscographyCard: FC<DiscographyCardProps> = ({
  discography,
  onDelete,
  onEdit,
  isLoading,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const onShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const onCancelAction = () => {
    setShowDeleteDialog(false);
  };

  const onConfirmAction = () => {
    onDelete(discography.year);
    onCancelAction();
  };

  return (
    <div className={styles["discography-container"]}>
      <div className={styles["discography-image"]}>
        {discography.image ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/images/discography/${discography.image}`}
            alt="Εικόνα δίσκου"
          />
        ) : (
          <p>Δεν υπάρχει εικόνα</p>
        )}
      </div>
      <div className={styles["discography-info"]}>
        <p>{discography.title}</p>
        <p>{discography.year}</p>
      </div>
      <div className={styles["discography-actions"]}>
        <AiFillEdit onClick={() => onEdit(discography.year)} />
        <AiFillDelete onClick={onShowDeleteDialog} />
      </div>
      <div className={styles["discography-actions-small"]}>
        <button
          className={styles["btn_primary"]}
          onClick={() => onEdit(discography.year)}
        >
          Επεξεργασία
        </button>
        <button className={styles["btn_error"]} onClick={onShowDeleteDialog}>
          Διαγραφή
        </button>
      </div>
      {showDeleteDialog && (
        <ModalDialog
          title="Διαγραφή δίσκου"
          content={`Είστε σίγουροι ότι θέλετε να διαγράψετε το δίσκο ${discography.title};`}
          actions={[
            {
              text: "Διαγραφή",
              style: ModalDialogActionStyle.Error,
              onClick: onConfirmAction,
            },
            {
              text: "Ακύρωση",
              style: ModalDialogActionStyle.Secondary,
              onClick: onCancelAction,
            },
          ]}
          onClose={onCancelAction}
        />
      )}
    </div>
  );
};

export default DiscographyCard;
