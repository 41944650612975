import { FC, useContext } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import classNames from "classnames";

import { IUomContestBrief } from "../../../interfaces/IUomContest";

import { useHttp } from "../../../hooks";

import { AuthContext } from "../../../context";
import { AuthContextType } from "../../../interfaces/AuthContext";

import { UomContestCard } from "..";

import styles from "./UomContestsGrid.module.css";

interface UomContestsGridProps {
  contests: IUomContestBrief[];
  onRefresh: () => void;
}

interface ApiResponse {
  message: string;
}

const UomContestsGrid: FC<UomContestsGridProps> = ({ contests, onRefresh }) => {
  const navigate: NavigateFunction = useNavigate();

  const { auth } = useContext(AuthContext) as AuthContextType;
  const { isLoading, error, sendRequest } = useHttp();

  // handle request to delete a contest
  const deleteHandler = (id: string) => {
    // transform API response
    const transformResponse = (response: ApiResponse) => {
      onRefresh();
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/uom/contest/delete/${id}`;

    sendRequest(
      { url, method: "DELETE", token: auth.token },
      transformResponse
    );
  };

  // navigate to edit UOM contest page
  const editHandler = (id: string) => {
    navigate(`/uom/contests/edit/${id}`);
  };

  return (
    <div className="section-padding">
      {error && error.trim() !== "" && <h2 className="text-error">{error}</h2>}
      {contests && contests.length > 0 && (
        <div className={classNames(styles["contests-grid"])}>
          {contests.map((contest, index) => (
            <UomContestCard
              key={`c-${contest.id}`}
              contest={contest}
              onDelete={deleteHandler}
              onEdit={editHandler}
              isLoading={isLoading}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default UomContestsGrid;
